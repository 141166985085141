import { Autocomplete, Button, Grid, TextField } from "@mui/material"
import * as projectConstants from "@/project/constants"
import { selectHasPermission, selectPermissions } from "@/auth/authSlice"
import { useSelector } from "react-redux"
import { useState } from "react"
import ConfirmationDialog from "@/common/ConfirmationDialog"
import { BoldInline } from "@/common/StyledComponents"
import WrapDialog from "./WrapDialog"
import { BID_STATUS_SIGNED } from "@/sheet/constants"

export default function ProjectFlow(props){
    const { children, onStatusUpdate, project, statuses, mode } = props

    const isProjectMode = mode == "project"

    const permissions = useSelector(selectPermissions)
    const statusPermission = useSelector(state => selectHasPermission(state, `from_project_status:${project.status.name}`))
    const projectStatusFlowPermission = useSelector(state => selectHasPermission(state, "project_status_flow"))
    const canUpdate = useSelector(state => selectHasPermission(state, "edit_projects"))

    const [wrapDialogOpen, setWrapDialogOpen] = useState(false)
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
    const [inProductionConfirmationOpen, setInProductionConfirmationOpen] = useState(false)
    const [inProductionDenialOpen, setInProductionDenialOpen] = useState(false)
    const [errors, setErrors] = useState({})

    const handleStatusChange = (status) => {
        if (!status) {
            throw "Missing status, please contact super admin"
        }

        if (!permissions.includes(`to_project_status:${status.name}`)) {
            setErrors({status_id: `"${status.name}" must be set by an authorized user.`})
            return
        }

        if (status.name == projectConstants.PROJECT_STATUS_IN_PRODUCTION && projectStatusFlowPermission && !project.legacy_bid) {
            if(project.confirmed_bid && project.confirmed_bid.id) {
                if (project.confirmed_bid.status == BID_STATUS_SIGNED) {
                    onStatusUpdate(status.id)       
                } else {
                    setInProductionConfirmationOpen(true)
                }
            } else {
                setInProductionDenialOpen(true)
            }
        } else if (status.name == projectConstants.PROJECT_STATUS_WRAP_IN_REVIEW) {
            setWrapDialogOpen(true)
        } else if (status.name == projectConstants.PROJECT_STATUS_CANCEL_IN_REVIEW) {
            setCancelDialogOpen(true)
        } else {
            onStatusUpdate(status.id)
        }
    }

    const onInproductionConfirm = () => {
        onStatusUpdate(statuses[projectConstants.PROJECT_STATUS_IN_PRODUCTION].id)
    }

    const onWrapDialogClose = (agreed) => {
        if (agreed) {
            onStatusUpdate(statuses[projectConstants.PROJECT_STATUS_WRAP_IN_REVIEW].id)
        }
        setWrapDialogOpen(false)
    }

    const onCancelConfirm = () => {
        setCancelDialogOpen(false)
        onStatusUpdate(statuses[projectConstants.PROJECT_STATUS_CANCEL_IN_REVIEW].id)
    }

    const getActionButtons = () => {
        const buttons = []
        const cancelPermission = permissions.includes(`to_project_status:${projectConstants.PROJECT_STATUS_CANCEL_IN_REVIEW}`)
        const cancelButton = (
            <Button
                variant="contained"
                color="error"
                onClick={() => handleStatusChange(statuses[projectConstants.PROJECT_STATUS_CANCEL_IN_REVIEW])}
            >
                Cancel Project
            </Button>
        )

        switch (project.status.name) {
            case projectConstants.PROJECT_STATUS_ON_RADAR:
            case projectConstants.PROJECT_STATUS_PITCHING:
            case projectConstants.PROJECT_STATUS_BRIEFED:
            case projectConstants.PROJECT_STATUS_ON_HOLD:
            case projectConstants.PROJECT_STATUS_ONGOING:
                if (cancelPermission && isProjectMode) {
                    buttons.push(cancelButton)
                }
                break
            case projectConstants.PROJECT_STATUS_IN_PRODUCTION:
                if (cancelPermission && isProjectMode) {
                    buttons.push(cancelButton)
                }
                buttons.push((
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleStatusChange(statuses[projectConstants.PROJECT_STATUS_WRAP_IN_REVIEW])}
                    >
                        Request Wrap
                    </Button>
                ))
                break
            case projectConstants.PROJECT_STATUS_CANCEL_IN_REVIEW:
                if (cancelPermission) {
                    buttons.push((
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleStatusChange(statuses[projectConstants.PROJECT_STATUS_CANCELLED])}
                            disabled={!statusPermission}
                        >
                            Cancel Project
                        </Button>
                    ))
                }
                break
            case projectConstants.PROJECT_STATUS_WRAP_IN_REVIEW:
                if (statusPermission) {
                    buttons.push((
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleStatusChange(statuses[projectConstants.PROJECT_STATUS_IN_PRODUCTION])}
                        >
                            Reject
                        </Button>
                    ))
                    buttons.push((
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleStatusChange(statuses[projectConstants.PROJECT_STATUS_WRAPPED])}
                        >
                            Approve
                        </Button>
                    ))
                } else {
                    buttons.push((
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {}}
                            disabled
                        >
                            Request Wrap
                        </Button>
                    ))
                }
                break
        }
        return buttons
    }

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="flex-start" sx={{ marginBottom: "48px" }}>
                <Grid item>
                    <Grid container spacing={2} alignItems={"center"}>
                        {isProjectMode &&
                        <Grid item>
                            <Autocomplete
                                sx={{width: 300}}
                                disabled={!statusPermission && canUpdate}
                                readOnly={!canUpdate}
                                value={project.status}
                                disablePortal
                                disableClearable
                                options={Object.values(statuses)}
                                getOptionLabel={(status) => status?.name}
                                renderInput={(params) => <TextField {...params} error={"status_id" in errors} helperText={errors["status_id"]} />}
                                onChange={(event, status) => handleStatusChange(status)}
                                blurOnSelect
                            />
                        </Grid>}
                        <Grid item>
                            { children }
                        </Grid>
                    </Grid>
                </Grid>
                {projectStatusFlowPermission && (
                    <Grid item>
                        <Grid container spacing={2}>
                            {getActionButtons().map((button, i) => (
                                <Grid item key={i}>
                                    {button}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                )}
            </Grid>

            <ConfirmationDialog
                open={inProductionConfirmationOpen}
                closeDialog={() => setInProductionConfirmationOpen(false)}
                title="Heads up..."
                callback={onInproductionConfirm}
            >
                Changing this project's status to <BoldInline>In Production</BoldInline> will automatically<br />
                mark the EP Approved bid <BoldInline>{ project.confirmed_bid?.name }</BoldInline> as signed.
            </ConfirmationDialog>

            <ConfirmationDialog
                open={inProductionDenialOpen}
                closeDialog={() => setInProductionDenialOpen(false)}
                title="Hang on..."
                callback={() => setInProductionDenialOpen(false)}
                hideOk
            >
                This project's status cannot be changed to <BoldInline>In Production</BoldInline> without a bid that has been approved by the EP.
            </ConfirmationDialog>

            <ConfirmationDialog
                open={cancelDialogOpen}
                closeDialog={() => setCancelDialogOpen(false)}
                title="Are you sure?"
                callback={onCancelConfirm}
            >
                This will notify finance who will review and finalize closing this project.
            </ConfirmationDialog>

            <WrapDialog open={wrapDialogOpen} handleClose={onWrapDialogClose} />
        </>
    )
}