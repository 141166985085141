import styled from 'styled-components'
import { useTheme } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import { formattedCurrency, formattedNumber } from "@/utils"
import { store } from '@/sheet/bidStore'


const Container = styled.div`
    background: ${props => props.theme.palette.background.paper};
    width: 100%;
    padding: 32px 46px;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 24px 0;
`

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;

    th {
        text-align: left;
        padding: 12px 48px 12px 0;
        width: 100%;
    }
    td {
        text-align: right;
        padding: 12px 48px;
        white-space: nowrap;

        &.rightBorder {
            border-right: ${props => `1px solid ${props.theme.palette.text.disabled}`};
        }

        &.warning {
            color: ${props => props.theme.palette.warning.main};

            span {
                color: ${props => props.theme.palette.warning.main};
            }
        }
        &.error {
            color: ${props => props.theme.palette.error.main};

            span {
                color: ${props => props.theme.palette.error.main};
            }
        }

        span {
            color: ${props => props.theme.palette.text.secondary};
            font-size: 12px;
            padding-right: 6px;
            white-space: nowrap;
        }
    }

    thead {
        th {
            padding-top: 0;
        }
        td {
            font-size: 12px;
            color: ${props => props.theme.palette.text.secondary};
            padding-top: 0;
        }

    }

    tbody {
        th {
            font-weight: normal;
            border-bottom: ${props => `1px solid ${props.theme.palette.text.disabled}`};
        }
        td {
            min-width: 130px;
            border-bottom: ${props => `1px solid ${props.theme.palette.text.disabled}`};
        }
    }

    tfoot {
        td {
            font-weight: bold;
        }
    }
`
const ToolTipContent = styled.div`

    p {
        text-align: right;
        margin: 0;

        &.active {
            font-weight: bold;
        }
    }
`

export default function BidInsights({ currency }){
    const theme = useTheme()

    const insights = store.bid.insights
    const goals = store.bid.goals

    return (
        <>
            <Container theme={theme}>
                <Table theme={theme}>
                    <thead>
                        <tr>
                            <th>Quoted</th>
                            <td className="rightBorder">Current Bid</td>
                            <td>Project Target</td>
                            <td>Project Total</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Hard Costs</th>
                            <td className="rightBorder">{formattedCurrency(insights.client_hard_cost_quoted, currency, true)}</td>
                            <td>-</td>
                            <td>{formattedCurrency(insights.project_hard_cost_quoted, currency, true)}</td>
                        </tr>
                        <tr>
                            <th>Producer Fees</th>
                            <td className="rightBorder">
                                <span>{formattedNumber(insights.producer_fee_quoted_percentage*100)}%</span>
                                {formattedCurrency(insights.producer_fee_quoted, currency, true)}
                            </td>
                            <Tooltip 
                                title={
                                    <ToolTipContent>
                                        Project Total Quoted
                                        <p className={insights.project_producer_fee_quoted_target_percentage == 0.25 ? "active" : ""}>
                                            &lt;= 100,000 = 25%
                                        </p>
                                        <p className={insights.project_producer_fee_quoted_target_percentage == 0.20 ? "active" : ""}>
                                            &lt;= 500,000 = 20%
                                        </p>
                                        <p className={insights.project_producer_fee_quoted_target_percentage == 0.15 ? "active" : ""}>
                                            &lt;= 1,000,000 = 15%
                                        </p>
                                        <p className={insights.project_producer_fee_quoted_target_percentage == 0.10 ? "active" : ""}>
                                            &gt; 1,000,000 = 10%
                                        </p>
                                    </ToolTipContent>
                                } 
                                placement="left"
                            >
                                <td>
                                    <span>{formattedNumber(insights.project_producer_fee_quoted_target_percentage*100)}%</span>
                                    {formattedCurrency(insights.project_producer_fee_quoted_target, currency, true)}
                                </td>
                            </Tooltip>
                            <td className={insights.project_producer_fee_quoted < insights.project_producer_fee_quoted_target ? "warning" : ""}>
                                <span>{formattedNumber(insights.project_producer_fee_quoted_percentage*100)}%</span>
                                {formattedCurrency(insights.project_producer_fee_quoted, currency, true)}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Total</th>
                            <td className="rightBorder">{formattedCurrency(insights.client_quoted, currency, true)}</td>
                            <Tooltip 
                                title={
                                    <ToolTipContent>
                                        (Project Hard Cost Budget / 0.9) + ((Producer Fee Quoted / 1.43) / 0.5)
                                    </ToolTipContent>
                                } 
                                placement="left"
                            >
                                <td>{formattedCurrency(insights.project_quoted_target, currency, true)}</td>
                            </Tooltip>
                            <td>{formattedCurrency(insights.project_quoted, currency, true)}</td>
                        </tr>
                    </tfoot>
                </Table>
            </Container>

            <Container theme={theme}>
                <Table theme={theme}>
                    <thead>
                        <tr>
                            <th>Profitability</th>
                            <td className="rightBorder">Current Bid</td>
                            <td>Project Target</td>
                            <td>Project Total</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Hard Cost Budget</th>
                            <td className="rightBorder">{formattedCurrency(insights.client_hard_cost_budget, currency, true)}</td>
                            <td>-</td>
                            <td>{formattedCurrency(insights.project_hard_cost_budget, currency, true)}</td>
                        </tr>
                        <tr>
                            <th>Markup</th>
                            <td className="rightBorder">
                                {formattedCurrency(insights.client_mark_up, currency, true)}
                            </td>
                            <td>-</td>
                            <td>
                                {formattedCurrency(insights.project_mark_up, currency, true)}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Net Profit</th>
                            <td className="rightBorder">
                                <span>{formattedNumber(insights.net_profit_percentage*100)}%</span>
                                {formattedCurrency(insights.new_net_profit, currency, true)}
                            </td>
                            <Tooltip 
                                title={
                                    <ToolTipContent>
                                        (Project Hard Cost Budget * 0.1) + ((Producer Fee Budget / 1.43) * 0.5)
                                    </ToolTipContent>
                                } 
                                placement="left"
                            >
                                <td>
                                    <span>{formattedNumber(insights.project_net_profit_target_percentage*100)}%</span>
                                    {formattedCurrency(insights.project_net_profit_target, currency, true)}
                                </td>
                            </Tooltip>
                            <td className={insights.project_new_net_profit < insights.project_net_profit_target ? "error" : ""}>
                                <span>{formattedNumber(insights.project_net_profit_percentage*100)}%</span>
                                {formattedCurrency(insights.project_new_net_profit, currency, true)}
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            </Container>
        </>
    )
}
