import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useTheme } from "@mui/material"
import Modal from "@mui/material/Modal"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import InputLabel from '@mui/material/InputLabel'
import Autocomplete from "@mui/material/Autocomplete"

import { request } from "@/Api"
import { BID_STATUS_DRAFT } from "@/sheet/constants"
import { CustomBox } from "@/common/StyledComponents"

export default function BidTemplatePopup(props){
    const theme = useTheme()
    const navigate = useNavigate()
    const { bidTemplatePopupOpen, setBidTemplatePopupOpen, bidId } = props
    const [ templateCategories, setTemplateCategories ] = useState([])
    const [ templateName, setTemplateName ] = useState("")
    const [ templateCategory, setTemplateCategory ] = useState("")
    const [ showTemplateSaved, setShowTemplateSaved ] = useState(false)

    const loadCategories = () => {
        request.get("/bid-templates/categories")
        .then((response) => {
            setTemplateCategories(response.data)
        })
    }

    const createTemplateBid = () => {
        request.post("/bids", {
            name: templateName,
            project_id: null,
            is_change_order: false,
            status: BID_STATUS_DRAFT,
            bid_type: "Template"
        })
        .then((response)=>{
            createTemplate(response.data.id)
            navigate("/bids/"+response.data.id)
        })
    }

    const copyTemplateBid = () => {
        request.post("/bids/copy", {
            bid_name: templateName,
            project_id: null,
            bid_id: bidId,
            bid_type: "Template",
            is_change_order: false,
            as_template: true
        })
        .then((response)=>{
            createTemplate(response.data.id)
        })
    }

    const createTemplate = (bid_id) => {
        request.post("/bid-templates", {
            category: templateCategory || null,
            bid_id: bid_id
        })
        .then(()=>{
            setBidTemplatePopupOpen(false)
            setShowTemplateSaved(true)
        })
    }

    useEffect(loadCategories, [])

    return (
        <>
            <Modal
                sx={{overflow: "scroll"}}
                open={bidTemplatePopupOpen}
                onClose={()=>{setBidTemplatePopupOpen(false)}}
            >
                <CustomBox sx={{ background: theme.palette.background.default }}>
                    <h1>{bidId ? `Copy` : `Create`} Template</h1>

                    <InputLabel>Name</InputLabel>
                    <TextField
                        fullWidth
                        value={templateName}
                        onChange={(e) => {setTemplateName(e.target.value)}}
                        sx={{ marginBottom: "24px" }}
                    />

                    <InputLabel>Category</InputLabel>
                    <Autocomplete
                        freeSolo
                        value={templateCategory}
                        options={templateCategories}
                        onInputChange={(e, value) => {setTemplateCategory(value)}}
                        renderInput={(params) => <TextField {...params} />}
                    />

                    <div style={{marginTop: "24px", textAlign: "right"}}>
                        <Button variant="contained" color="secondary" sx={{ marginRight: "6px" }} onClick={()=> setBidTemplatePopupOpen(false)}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={()=>{bidId ? copyTemplateBid() : createTemplateBid()}}>Save</Button>
                    </div>
                </CustomBox>
            </Modal>
            <Snackbar
                open={showTemplateSaved}
                autoHideDuration={2000}
                onClose={(event, reason) => {setShowTemplateSaved(false)}}
            >
                <Alert severity="success">Template Created!</Alert>
            </Snackbar>
        </>
    )
}
