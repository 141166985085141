import { AddCircleRounded } from "@mui/icons-material"
import { Grid, IconButton, Paper } from "@mui/material"
import AddonForm from "./AddonForm"
import { request } from "@/Api"
import { store } from "./bidStore"

export default function AddonsForm({addons}) {
    const bidId = store.bid.id

    const handleAddonBlur = (addon) => (field, val) => {
        if (!addon.id) {
            return
        }
        const updatedAddon = {...addon, [field]: val}
        request.put(`/bids/${bidId}/addon/${addon.id}`, updatedAddon)
    }

    const handleAddonDelete = (addon) => () => {
        request.delete(`/bids/${bidId}/addon/${addon.id}`)
    }

    const handleAddonAdd = () => {
        request.post(`/bids/${bidId}/addon`, {name: "New Addon", type: "amount", amount: 0, bid_id: bidId})
    }

    if (!addons) {
        return
    }

    return (
        <Paper style={{boxShadow: "none", padding: "5px 30px 30px 30px", marginTop: 60}}>
            <h4>Global add-ons</h4>
            {
                addons.map(addon=>
                    <AddonForm 
                        key={addon.id}
                        onAddonBlur={handleAddonBlur(addon)}
                        onAddonDelete={handleAddonDelete(addon)}
                        addonData={addon}
                    />
                )
            }

            <Grid container alignItems={"center"}>
                <Grid item>
                    <IconButton
                        size="small"
                        onClick={handleAddonAdd}
                    >
                        <AddCircleRounded color="success" fontSize="tiny"/>
                    </IconButton>
                </Grid>
                <Grid item fontSize={14}>
                    Add
                </Grid>
            </Grid>
        </Paper>
    )
}