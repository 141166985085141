import { ProfileListItem } from "@/profiles_common/CommonComponents"

// NOTE: utils for convert object data to grid data
export const getColumns = (columnProfiles) => {
    const columns = []
    columnProfiles.forEach(columnProfile=>{
        columns.push(
            {
                columnId: columnProfile.id,
                width: columnProfile.width || 120
            }
        )    
    })
    return columns
}

export const headerRow = (columnProfiles) => {
    const cells = []
    columnProfiles.forEach(columnProfile=>{
        cells.push(
            {
                type: "text",
                text: columnProfile.name || columnProfile.id,
                renderer: () => <ProfileListItem icon={columnProfile.headerIcon} text={columnProfile.headerText}/>,
                nonEditable: true,
            }
        )
    })
    return {
        rowId: "header",
        cells: cells
    }
}

export const getRow = (obj, columnProfiles, hasChildren, parentId, indent, height, isExpanded, openedDropdown, checked, customDropdownMappings) => {
    const cells = []
    columnProfiles.forEach(columnProfile=>{
        // TODO: push cell by type, don't merge
        if (columnProfile.id in obj || columnProfile.renderer) {
            const customDropdownMapping = customDropdownMappings && customDropdownMappings[columnProfile.id]
            cells.push(
                columnProfile.type == "dropdown" ? {
                    type: columnProfile.type,
                    selectedValue: customDropdownMapping ? customDropdownMapping[obj[columnProfile.id]] : obj[columnProfile.id],
                    values: columnProfile.values,
                    isDisabled: false,
                    isOpen: openedDropdown ? openedDropdown.rowId == obj.id && openedDropdown.columnId == columnProfile.id : false,
                    inputValue: columnProfile.inputValue
                } : {
                    rowId: obj.id,
                    type: columnProfile.type,
                    text: obj[columnProfile.id] || "",
                    value: obj[columnProfile.id] || 0,
                    parentId: parentId,
                    isExpanded:isExpanded == null ? false : isExpanded,
                    hasChildren: hasChildren,
                    indent: indent,
                    renderer: columnProfile.renderer,
                    nonEditable: columnProfile.nonEditable,
                    style: columnProfile.style,
                    format: columnProfile.format,
                    checked: checked,
                }
            )
        } else {
            cells.push({
                type: "text",
                text: "",
                nonEditable: true
            })
        }
    })

    return {
        rowId: obj.id,
        reorderable: true,
        cells: cells,
        indent: indent,
        height: height
    }
}

export const reorderArray = (arr, idxs, to) => {
    const movedElements = arr.filter((_, idx) => idxs.includes(idx));
    const targetIdx = Math.min(...idxs) < to ? to += 1 : to -= idxs.filter(idx => idx < to).length;
    const leftSide = arr.filter((_, idx) => idx < targetIdx && !idxs.includes(idx));
    const rightSide = arr.filter((_, idx) => idx >= targetIdx && !idxs.includes(idx));
    return [...leftSide, ...movedElements, ...rightSide];
}

// NOTE: Other utils

export const shuffle = (array) => {
    let currentIndex = array.length;
    while (currentIndex != 0) {
        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
}
