import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet"
import { DragDropContext, Droppable } from "react-beautiful-dnd"

import { useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import CircularProgress from "@mui/material/CircularProgress";

import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';

import BidLoading from "@/bid/BidLoading";
import BidHeader from "@/sheet/BidHeader";
import BidInsert from "@/bid/BidInsert";
import BidSum from "@/sheet/BidSum";
import BidSection from "@/bid/BidSection";
import BidTotals from "@/bid/BidTotals";
import BidAddOns from "@/bid/BidAddOns";
import BidNotes from "@/sheet/BidNotes";
import BidBills from "@/sheet/BidBills";
import Comments from "@/common/Comments"
import BidActivity from "@/bid/BidActivity";
import LineGroupPopup from "@/sheet/LineGroupPopup";
import ConfirmationDialog from "@/common/ConfirmationDialog";
import { SearchBox } from "@/common/StyledComponents";

import { request } from "@/Api";
import { bidSections, BID_SECTION_PRODUCTION_SERVICES, BID_SECTION_TALENT, BID_SECTION_TRAVEL } from "@/sheet/constants";
import { generateRandomColor, getCurrencySymbol } from "@/utils"
import { SOCKET_BASE_URL } from "@/config"
import { selectReadOnly, selectIsTemplate, selectSections, selectClientCurrency, selectOfficeCurrency, selectComments, selectActivity, updateBid, updateInsights, updateTotals, newGroup, sortSections, sortCategories, sortLines, selectLoading, searchLines, highlightLines, apiSortRequest, updateGroup, updateLine, addSection, deleteSection, deleteLine, addLine, insertLine, addCategory, deleteCategory, updateAddons, updateAddon, updateBills, selectProject, updateLineCalculatedFields, updateLinesAndGroups, selectName } from "@/bid/bidSlice"
import { selectToken, selectHasPermission, selectName as selectPersonName } from "@/auth/authSlice"
import { useBid } from "./bidHooks";
import { useSetOrgId } from "@/common/hooks";

const color = generateRandomColor()

export default function Bid(){
    const { bidId } = useParams()

    useSetOrgId()

    const theme = useTheme()
    
    const socket = useRef()
    const socketConnectionEstablished = useRef(false)

    const dispatch = useDispatch() 

    const token = useSelector(selectToken)
    const personName = useSelector(selectPersonName)
    const canViewConfidentialData = useSelector(state => selectHasPermission(state, "view_confidential_data"))
    const expandAllByDefault = !canViewConfidentialData
    
    const [ expandAll, setExpandAll ] = useState(expandAllByDefault)

    const [ serviceItems, setServiceItems ] = useState([])
    const [ isCleaning, setIsCleaning ] = useState(false)
    const [ clearLinesConfirmationOpen, setClearLinesConfirmationOpen ] = useState(false)

    const isLoading = useSelector(selectLoading)
    const isTemplate = useSelector(selectIsTemplate)
    const readOnly = useSelector(selectReadOnly)
    const bidName = useSelector(selectName)
    const officeCurrency = useSelector(selectOfficeCurrency)
    const clientCurrency = useSelector(selectClientCurrency)
    const comments = useSelector(selectComments)
    const activity = useSelector(selectActivity)
    const sections = useSelector(selectSections) 
    const project = useSelector(selectProject)
    const getBid = useBid()
    
    const getServiceItems = () => {
        request.get("/service-items", {
            params: {currency: clientCurrency},
        })
        .then((response)=>{
            setServiceItems(response.data)
        })
    }
    useEffect(getServiceItems, [clientCurrency])

    useEffect(()=>{
        getBid(bidId)

        socket.current = new WebSocket(`${SOCKET_BASE_URL}/ws/bid/${bidId}?token=${token}`)

        socket.current.onclose = () => {
            if(socketConnectionEstablished.current == true) {
                location.reload()
            }
        }

        socket.current.onmessage = (event) => {
            socketConnectionEstablished.current = true

            const message = JSON.parse(event.data)

            if (message.type == "connect") {
                dispatch(highlightLines(message.data))

                socket.current.send(JSON.stringify({
                    type: "connect",
                    data: {
                        personName,
                        color,
                    }
                }))
            }
            else if (message.type == "highlight"){
                dispatch(highlightLines(message.data))
            }
            else if (message.type == "update"){
                if (message.data.insights){
                    dispatch(updateInsights(message.data.insights))
                }
                if (message.data.totals){
                    dispatch(updateTotals(message.data.totals))
                }
                if (message.data.details){
                    dispatch(updateBid(message.data.details))
                }
                if (message.data.lineGroup) {
                    dispatch(updateGroup(message.data.lineGroup))
                }
                if (message.data.lineItem) {
                    dispatch(updateLineCalculatedFields(message.data.lineItem))
                    dispatch(updateLine(message.data.lineItem))
                }
                if (message.data.addons) {
                    dispatch(updateAddons(message.data.addons))
                }
                if (message.data.addon) {
                    dispatch(updateAddon(message.data.addon))
                }
                if (message.data.bills) {
                    dispatch(updateBills(message.data.bills))
                }
            }
            else if (message.type == "add") {
                if (message.data.lineGroup) {
                    const newLineGroup = message.data.lineGroup
                    dispatch(newLineGroup.parent_id ? addCategory(newLineGroup) : addSection(newLineGroup))
                }
                if (message.data.lineItem) {
                    dispatch(updateInsights(message.data.insights))
                    dispatch(updateTotals(message.data.totals))
                    dispatch(message.data.index ? insertLine({ line: message.data.lineItem, index: message.data.index }) : addLine(message.data.lineItem))
                }
                if (message.data.totals) {
                    dispatch(updateTotals(message.data.totals))
                }
                if (message.data.addons) {
                    dispatch(updateAddons(message.data.addons))
                }
            }
            else if (message.type == "delete") {
                dispatch(updateInsights(message.data.insights))
                dispatch(updateTotals(message.data.totals))

                if (message.data.lineGroup) {
                    const deletedGroup = message.data.lineGroup
                    dispatch(deletedGroup.parent_id ? deleteCategory(deletedGroup) : deleteSection(deletedGroup))
                }
                if (message.data.lineItem) {
                    dispatch(deleteLine(message.data.lineItem))
                }
                if (message.data.addons) {
                    dispatch(updateAddons(message.data.addons))
                }
                if (message.data.bills) {
                    dispatch(updateBills(message.data.bills))
                }
            }
            else if (message.data.type == "sort") {
                if (message.data.sectionId) {
                    dispatch(sortSections(message.data))
                }
                else if (message.data.categoryId) {
                    dispatch(sortCategories(message.data))
                }
                else if (message.data.lineId) {
                    dispatch(sortLines(message.data))
                }
            }
        }
    }, [])

    const sendSocketMessage = (payload) => {
        socket.current.send(JSON.stringify(payload))
    }

    const getSectionDefaultMarkup = (sectionName) => {
        if ([BID_SECTION_TALENT, BID_SECTION_TRAVEL].includes(sectionName)){
            if (sectionName == BID_SECTION_TRAVEL && project.client.name.includes("Pepsi")){
                return 0.1
            }
            return 0.15
        }else if (sectionName == BID_SECTION_PRODUCTION_SERVICES){
            return 0
        }else{
            return 0.3
        }
    }

    const createSection = (value) => {
        if(!value){return}

        dispatch(newGroup({
            name: value,
            markup: getSectionDefaultMarkup(value),
            is_makers_fees: false,
            bid_id: bidId,
            sort_order: sections.length + 1,
            rate_type: "Days",
            lineGroupType: "section",
            popupType: "create"
        }))
    }
    
    const handleSearch = (event) => {          
        dispatch(searchLines(event.target.value))
    }

    const handleClearEmpty = () => {
        setIsCleaning(true)
        request.put(`/bids/${bidId}/clean`)
        .then((response)=>{
            dispatch(updateLinesAndGroups(response.data.line_items_grouped))
            setIsCleaning(false)
        })
    }

    const handleDragEnd = (result) => {
        const { destination, source, type, draggableId } = result
        if(!destination) {
            return
        }

        let payload = {}

        if(type == "section") {
            payload = {
                sectionId: parseInt(draggableId.split("_").pop()),
                sourceIndex: source.index,
                destinationIndex: destination.index,
            }
            dispatch(sortSections(payload))
        } else if (type == "category") {
            payload = {
                categoryId: parseInt(draggableId.split("_").pop()),
                sourceId: parseInt(source.droppableId.split("_").pop()),
                sourceIndex: source.index,
                destinationId: parseInt(destination.droppableId.split("_").pop()),
                destinationIndex: destination.index,
            }
            dispatch(sortCategories(payload))
        } else if (type == "line") {
            payload = {
                lineId: parseInt(draggableId.split("_").pop()),
                sourceId: parseInt(source.droppableId.split("_").pop()),
                sourceIndex: source.index,
                destinationId: parseInt(destination.droppableId.split("_").pop()),
                destinationIndex: destination.index,
            }
            dispatch(sortLines(payload))
        }

        dispatch(apiSortRequest(bidId, payload))
        sendSocketMessage({ ...payload, type: "sort" })
    }

    return isLoading ? (
        <BidLoading />
    ) : (
        <>
            <Helmet><title>{bidName || "Loading"} - Makers Central</title></Helmet>
            
            <BidHeader bidId={bidId} sendSocketMessage={sendSocketMessage} />
            {/* {!isTemplate ? <BidHeader /> : <h1>Edit template</h1>} */}
            
            <Grid container justifyContent={"space-between"} paddingBottom={3}>
                <div>
                    <Button onClick={()=>{setExpandAll(!expandAll)}} endIcon={expandAll ? <UnfoldLessIcon/> : <UnfoldMoreIcon />}>
                        {expandAll ? "Collapse all"  : "Expand all"}
                    </Button>
                    { !isTemplate ? (
                        <>
                            {isCleaning ? <CircularProgress /> : (
                                <Button
                                    onClick={() => setClearLinesConfirmationOpen(true)}
                                    endIcon={<ContentPasteOffIcon />}
                                    sx={{marginLeft: "24px"}}
                                >
                                    {`Archive ${getCurrencySymbol(clientCurrency)}0 lines`}
                                </Button>
                            )}

                            <ConfirmationDialog
                                open={clearLinesConfirmationOpen}
                                closeDialog={() => setClearLinesConfirmationOpen(false)}
                                title="Are you sure?"
                                callback={handleClearEmpty}
                            >
                                {`This will remove all lines with a ${getCurrencySymbol(clientCurrency)}0 total, plus any empty categories and sections.`}
                            </ConfirmationDialog>

                        </>
                    ) : null }
                </div>
                <SearchBox handleSearch={handleSearch}/>
            </Grid>
            
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable type="section" droppableId="main-droppable">
                    {(provided)=>
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {
                            sections.map((sectionId, sectionIndex)=>{
                                return(
                                    <BidSection
                                        key={`section_${sectionId}`}
                                        sectionId={sectionId}
                                        expandAll={expandAll}
                                        currency={clientCurrency}
                                        deleteMessage="Deleting a section will remove all categories and line items contained inside. This is non-reversable."
                                        readOnly={readOnly}
                                        sx={{ "::before": {display: "none"}, background: theme.palette.background.paper, borderRadius: 1.5, border: "none", marginBottom: 3}}
                                        draggableId={`section_${sectionId}`}
                                        draggableIndex={sectionIndex}
                                        lineGroupType={"section"}
                                        sendSocketMessage={sendSocketMessage}
                                        officeCurrency={officeCurrency}
                                        canViewConfidentialData={canViewConfidentialData}
                                        serviceItems={serviceItems}
                                        isTemplate={isTemplate}
                                    />
                                )
                            })
                        }
                        {provided.placeholder}
                    </div>}
                </Droppable>
            </DragDropContext>

            {readOnly ? null : (
                <BidInsert
                    type="section"
                    options={bidSections}
                    createItem={createSection}
                />
            )}
            
            <BidTotals />

            <Divider sx={{marginTop: 6, marginBottom: 2}}/>
            
            {canViewConfidentialData ? 
                <>
                    <BidNotes 
                        bidId={bidId}
                        readOnly={readOnly} 
                    />
                    <Divider sx={{marginTop: 6, marginBottom: 2}}/>
                    <BidAddOns 
                        bidId={bidId}
                        readOnly={readOnly} 
                        sendSocketMessage={sendSocketMessage}
                    />
                    
                    <Divider sx={{marginTop: 6, marginBottom: 2}}/>
                    
                    {isTemplate ? null : (
                        <>
                            <BidBills
                                bidId={bidId}
                                legacy={project.legacy_bills}
                            />
                            <Divider sx={{marginTop: 6, marginBottom: 6}}/>
                        </>
                    )}
              
                    {!isTemplate ? <Grid container spacing={8} sx={{marginBottom: 6}}>
                        {canViewConfidentialData ? (
                            <Grid item md={8}>
                                <Comments comments={comments} modelType={"bid"} modelInstanceId={bidId} />
                            </Grid>
                        ) : null}
                        <Grid item md={4}>
                            {activity.map(activity => (
                                <BidActivity key={activity.id} {...activity} />
                            ))}
                        </Grid>
                    </Grid> : null}
                </> 
            : null}
                        
            <BidSum
                isTemplate={isTemplate}
                canViewConfidentialData={canViewConfidentialData}
            />

            <LineGroupPopup sendSocketMessage={sendSocketMessage} />
        </>
    )
}
