import { startOfMonth, endOfMonth, format } from "date-fns"

import Grid from '@mui/material/Unstable_Grid2';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'

import ApiAutocomplete from "@/common/ApiAutocomplete";
import { dateQueryToFilterValue, queryToFilterValue } from "@/utils";
import { BID_TYPES } from "@/sheet/constants"

export default function BillFilters(props) {
    const {filters, setFilters, handleAutocompleteChange, handleAutocompleteClear} = props

    const handleCalendarChange = (filterName) => (newValue) => {
        if(new Date(newValue) != "Invalid Date" && newValue != null){
            setFilters({...filters, [filterName]: [
                "gte:"+format(startOfMonth(newValue), "y-MM-dd"),
                "lte:"+format(endOfMonth(newValue), "y-MM-dd")
            ]})
        }else if (newValue == null){
            let newFilters = {...filters}
            delete newFilters[filterName]
            setFilters(newFilters)
        }
    }

    return (
        <Grid container sx={{ paddingTop: 2 }} spacing={2}>
            <Grid xs={6} md={4}>
                <ApiAutocomplete
                    silentErrors
                    value={queryToFilterValue(filters.client_id)}
                    apiRoute={"clients"}
                    fields={["name"]}
                    onChange={handleAutocompleteChange("client_id")}
                    onInputChange={handleAutocompleteClear("client_id")}
                    label="Client"
                />
            </Grid>
            <Grid xs={6} md={4}>
                <ApiAutocomplete
                    silentErrors
                    value={queryToFilterValue(filters.project_status)}
                    apiRoute={"projects"}
                    fields={["name"]}
                    onChange={handleAutocompleteChange("project_id")}
                    onInputChange={handleAutocompleteClear("project_id")}
                    label="Project"
                    simple={true}
                />
            </Grid>
            <Grid xs={6} md={4}>
                <ApiAutocomplete
                    silentErrors
                    value={queryToFilterValue(filters.project_status_id)}
                    apiRoute={"statuses"}
                    fields={["name"]}
                    onChange={handleAutocompleteChange("project_status_id")}
                    onInputChange={handleAutocompleteClear("project_status_id")}
                    label="Project Status"
                    simple={true}
                />
            </Grid>
            <Grid xs={6} md={2}>
                <ApiAutocomplete 
                    silentErrors
                    value={filters.legal_entity_id}
                    apiRoute={"legal-entities"}
                    fields={["name"]}
                    onChange={handleAutocompleteChange("legal_entity_id")}
                    onInputChange={handleAutocompleteClear("legal_entity_id")}
                    label="Entity"
                />
            </Grid>
            <Grid xs={6} md={2}>
                <Autocomplete
                    value={queryToFilterValue(filters.sent)}
                    disablePortal
                    options={["true", "false"]}
                    getOptionLabel={(option) => option == "true" ? "yes" : "no"}
                    renderInput={(params) => <TextField {...params} label="Sent" />}
                    onChange={handleAutocompleteChange("sent")}
                    onInputChange={handleAutocompleteClear("sent")}
                /> 
            </Grid>
            <Grid xs={6} md={2}>
                <Autocomplete
                    value={queryToFilterValue(filters.signed)}
                    disablePortal
                    options={["true", "false"]}
                    getOptionLabel={(option) => option == "true" ? "yes" : "no"}
                    renderInput={(params) => <TextField {...params} label="Signed" />}
                    onChange={handleAutocompleteChange("signed")}
                    onInputChange={handleAutocompleteClear("signed")}
                /> 
            </Grid>
            <Grid xs={6} md={2}>
                <Autocomplete
                    value={queryToFilterValue(filters.bill_type)}
                    disablePortal
                    options={[...BID_TYPES, "Forecast"]}
                    renderInput={(params) => <TextField {...params} label="Bill Type" />}
                    onChange={handleAutocompleteChange("bill_type")}
                    onInputChange={handleAutocompleteClear("bill_type")}
                /> 
            </Grid>
            <Grid xs={6} md={2}>
                <Autocomplete
                    value={queryToFilterValue(filters.certainty)}
                    disablePortal
                    options={["Confident", "Stretch", "Actual"]}
                    renderInput={(params) => <TextField {...params} label="Certainty" />}
                    onChange={handleAutocompleteChange("certainty")}
                    onInputChange={handleAutocompleteClear("certainty")}
                /> 
            </Grid>
            <Grid item xs={6} md={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        views={["month", "year"]}
                        value={dateQueryToFilterValue(filters.bill_date ? filters.bill_date[0] : null)}
                        onChange={handleCalendarChange("bill_date")}
                        renderInput={(params) => <TextField {...params} label="Bill Month"/>}
                    />
                </LocalizationProvider>
            </Grid>
        </Grid>            
    )
}
