import * as React from "react";
import {
    isNavigationKey,
    getCellProperty,
    isAlphaNumericKey,
    keyCodes,
} from "@silevis/reactgrid";
import "./flag-cell-style.css";
import { tryParseFormula } from "./formulaParser";
import { formattedCurrency } from "@/utils";

export class FormulaCellTemplate {
    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text
        const value = parseFloat(getCellProperty(uncertainCell, "value", "number"))

        return { ...uncertainCell, text, value }
    }

    handleKeyDown(
        cell,
        keyCode,
        ctrl,
        shift,
        alt
    ){
        if (!ctrl && !alt && isAlphaNumericKey(keyCode))
            return { cell, enableEditMode: true };
        return {
            cell,
            enableEditMode:keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
        }
    }

    update(
        cell,
        cellToMerge
    ){
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text, value: cellToMerge.value });
    }

    render(
        cell,
        isInEditMode,
        onCellChanged = (cell, commit) => {}
    ){
        if (!isInEditMode) {
            return (
                <span>{formattedCurrency(cell.value)}</span>
            )
        }
        return (
            <input
                ref={(input) => {
                    input && input.focus();
                }}
                defaultValue={cell.value}
                onChange={(e) =>
                    onCellChanged(
                        this.getCompatibleCell({ ...cell, text: e.currentTarget.value, value: parseFloat(e.currentTarget.value) }),
                        false
                    )
                }
                onCopy={(e) => e.stopPropagation()}
                onCut={(e) => e.stopPropagation()}
                onPaste={(e) => e.stopPropagation()}
                onPointerDown={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
                    e.stopPropagation();
                }}
                onBlur={(e) => 
                    {
                        const val = e.currentTarget.value == "" ? 0 : e.currentTarget.value
                        const parsedVal = tryParseFormula(val)
                        onCellChanged(
                            this.getCompatibleCell({ ...cell, text: e.currentTarget.value, value: parseFloat(parsedVal) }),
                            true
                        )
                    }
                }
            />
        )
    }
}
