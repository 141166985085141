import styled from "styled-components";
import { format } from "date-fns"

import { useTheme } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Grid from "@mui/material/Grid";

import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';

import * as constants from "@/sheet/constants"

const Activity = styled(Grid)`
    margin: 24px 0;
`
const Title = styled.h4`
    margin: 4px 0 12px 0;
`
const Timestamp = styled.span`
    font-weight: normal;
    margin-left: 18px;
    color: grey;
`

export default function BidActivity(props) {
    const { action, note, person, created_at } = props
    const theme = useTheme()
    
    const getIcon = () => {
        let backgroundColor = "#000"
        let Icon = null

        if([constants.BID_ACTIVITY_SUBMITTED_EP, constants.BID_ACTIVITY_SUBMITTED_FINANCE, constants.BID_ACTIVITY_REQUESTED_SIGNATURE].includes(action)) {
            backgroundColor = "#CDCDCD80"
            Icon = SendIcon
        } else if (action == constants.BID_ACTIVITY_APPROVED) {
            backgroundColor = "#00a388"
            Icon = DoneIcon
        } else if (action == constants.BID_ACTIVITY_CANCELLED) {
            backgroundColor = "#f4af00"
            Icon = CancelIcon
        } else if (action == constants.BID_ACTIVITY_REJECTED) {
            backgroundColor = "#ff2b2b"
            Icon = CloseIcon
        } else if (action == constants.BID_ACTIVITY_SIGNED) {
            backgroundColor = "#00a388"
            Icon = ThumbUpAltIcon
        } else {
            backgroundColor = theme.palette.primary.main
            Icon = ThumbUpAltIcon
        }
        
        return (
            <Avatar sx={{backgroundColor: backgroundColor, color: "#fff", width: 32, height: 32}}>
                <Icon fontSize="small" />
            </Avatar>
        )
    }

    return (
        <Activity container spacing={2} sx={{borderBottom: `1px solid ${theme.palette.divider}`}}>
            <Grid item>
                {getIcon()}
            </Grid>
            <Grid item>
                <Title>{action.charAt(0).toUpperCase() + action.slice(1)}</Title>
                <p>{note}</p>
                <h6>
                    {`${person.first_name} ${person.last_name}`}
                    <Timestamp>{format(new Date(created_at), "y-MM-dd h:mm aaa")}</Timestamp>
                </h6>
            </Grid>
        </Activity>
    )
}
