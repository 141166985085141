import { request } from "@/Api"

export let serviceItems = []

export const getServiceItemsByCategory = (category) => {
    return serviceItems.filter(item=>item.category == category && item.currency == "CAD")
}

export const getServiceItems = () => {
    request.get("service-items")
    .then(response=>{
        serviceItems = response.data
    })
}