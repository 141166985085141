// NOTES: asyncrounous setstate is not guaranteed to be in sync with the order of calls, such as making external requests

// import { request } from "@/Api"
// import { memo, useEffect, useState } from "react"

// const randomString = () => {
//     return Math.random().toString(36).substring(7)
// }

// const Child1 = memo(({state}) => {
//     console.log("Child1 render")
//     return (
//         <h1>{state}</h1>
//     )
// })

// const Child2And3 = ({state2, state3}) => {
//     console.log("Child2And3 render")
//     return (
//         <div>
//             <h2>{state2}</h2>
//             <h2>{state3}</h2>
//         </div>
//     )
// }

// export default function BidSheet() {
//     console.log("BidSheet render")
    
//     const [ testState1, setTestState1 ] = useState()
//     const [ testState2, setTestState2 ] = useState()
//     const [ testState3, setTestState3 ] = useState()
//     const [ testState4, setTestState4 ] = useState()
//     const handleSetStates = () => {
//         setTestState4(randomString())
//     }
//     const getBid = () => {
//         request.get(`/bids/${10}`)
//         .then(response=>{
//             setTestState4(randomString())
//         })
//     }
//     useEffect(()=>{
//         handleSetStates()
//         getBid()
//     }, [])
//     return (
//         <div>
//             <h1>BidSheet</h1>
//             <Child1 state={testState1}/>
//             <Child2And3 state2={testState2} state3={testState3}/>
//             <button onClick={handleSetStates}>Set States</button>
//         </div>
//     )
// }



// TEST



import { useEffect, useRef, useState } from "react"
import { ReactGrid } from "@silevis/reactgrid"

import { getColumns, reorderArray } from "./sheetUtils"
import { bidToRows, columnProfiles, getProcessedColumnProfiles, getReadOnly, getRootAddons, store, storeBid } from "./bidStore"

import "./styles.scss"

import { request } from "@/Api";
import { SOCKET_BASE_URL } from "@/config"
import { useSelector } from "react-redux"
import { selectHasPermission, selectToken } from "@/auth/authSlice"
import { ADDON_ROW_ID_PREFIX, LINE_GROUP_ROW_ID_PREFIX, LINE_ITEM_ROW_ID_PREFIX, ROW_CELL_HEIGHT } from "./constants"
import { getServiceItems } from "./serviceItemsStore"
import { Button, Divider, Grid, IconButton, Menu, MenuItem, Paper, Skeleton } from "@mui/material"
import { generateRandomColor } from "@/utils"

import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import { FormulaCellTemplate } from "./FormulaCellTemplate"
import { tryParseFormula } from "./formulaParser"
import CustomChevronCellTemplate from "./CustomChevronCellTemplate"
import BidHeader from "@/sheet/BidHeader"
import BidSum from "@/sheet/BidSum"
import LineGroupMenu from "./LineGroupMenu"
import ConfirmationDialog from "@/common/ConfirmationDialog"
import BidBills from "@/sheet/BidBills"
import BidNotes from "@/sheet/BidNotes"
import AddonForm from "./AddonForm"
import AddonsForm from "./AddonsForm"
import LineGroupPopup from "@/sheet/LineGroupPopup"
import Comments from "@/common/Comments"
import BidActivity from "@/bid/BidActivity"
import HighlightsOverview from "./HighlightsOverview"
import { useParams } from "react-router-dom"

const highlightColor = generateRandomColor()

const isRowFullyExpanded = (rowsHash, row) => {
    let currentRow = row
    let chevron = getChevronCellFromRow(currentRow)
    if (!chevron) {
        return true
    }
    while (currentRow && chevron && chevron.parentId != null) {
        currentRow = rowsHash[chevron.parentId]
        chevron = getChevronCellFromRow(currentRow)
        if (!chevron.isExpanded) {
            return false
        }
    }
    return true
}

const getVisibleRows = (rows) => {
    const rowsHash = rowsToHashTable(rows)
    return rows.filter(row => isRowFullyExpanded(rowsHash, row))
}

const getChevronCellFromRow = (row) => {
    return row.cells.find(cell=>cell.type == "chevron" || cell.renderer != null) // don't generalize what a cheveron row is
}

const rowsToHashTable = (rows) => {
    return Object.fromEntries(rows.map(row => [row.rowId, row]))
}

export default function BidSheet(){  
    const { BID_ID } = useParams() // TODO: use path param
    const [ rows, setRows ] = useState([])
    const [ columns, setColumns ] = useState([])
    const [ columnRightStickyCount, setColumnRightStickyCount ] = useState([])
    const [ expandAll, setExpandAll ] = useState(false)
    const [ columnMenuOpen, setColumnMenuOpen ] = useState(false)
    const [ lineGroupPopupData, setLineGroupPopupData] = useState(null)
    const [ lineGroupMenuAnchor, setLineGroupMenuAnchor ] = useState(null)

    const socket = useRef()
    const columnMenuAnchor = useRef()
    const token = useSelector(selectToken)
    const canViewConfidentialData = useSelector(state => selectHasPermission(state, "view_confidential_data"))
    const rowsToRender = getVisibleRows(rows)

    const reactGridRef = useRef()

    const readOnly = getReadOnly()
    const rootAddons = getRootAddons()

    const isTemplate = store.bid.project_id == null

    // workaround for child state control, should be refactored because inverted data flow
    const setHighlightsRef = useRef()
    const setSumDataRef = useRef()
    const registerChildHighlightsFunction = (func) => {
        setHighlightsRef.current = func
    }
    const registerChildSumDataFunction = (func) => {
        setSumDataRef.current = func
    }

    const sendSocketMessage = (payload) => {
        socket.current.send(JSON.stringify(payload))
    }

    const handleSocketClose = () => {
        // location.reload()
    }

    const setRowsFromBid = () => {
        setRows(bidToRows())
    }

    const refreshBid = (bid) => {
        storeBid(bid)
        setRowsFromBid()
    }

    const handleSocketData = (event) => {
        const payloadParsed = JSON.parse(event.data)
        const payloadType = payloadParsed.type
        const data = payloadParsed.data
        if (payloadType == "bid") {
            refreshBid(data)
        } else if (payloadType == "highlight") {
            setHighlightsRef.current(data)
        } else {
            
        }
    }

    const getBid = () => {
        request.get(`/bids/${BID_ID}`)
        .then(response=>{
            refreshBid(response.data)
        })
    }

    const updateLineItem = (id, field, val) => {
        const line = store.bid.lineItems.find(line=>line.id == id)
        request.put(`bids/${BID_ID}/line-items/${id}`, {...line, [field]: val})
    }

    const updateLineGroup = (id, field, val) => {
        const group = store.bid.lineGroups.find(group=>group.id == id)
        request.put(`bids/${BID_ID}/line-groups/${id}`, {...group, [field]: val})
    }

    const updateAddon = (id, field, val) => {
        const addon = store.bid.addons.find(addon=>addon.id == id)
        request.put(`bids/${BID_ID}/addon/${id}`, {...addon, [field]: val})
    }

    const orderChildren = (parentId, childrenIds) => {
        if (parentId) {
            request.put(`/bids/${BID_ID}/line-groups/${parentId}/order`, childrenIds)
        } else {
            request.put(`/bids/${BID_ID}/line-groups/order`, childrenIds)
        }
    }

    useEffect(()=>{
        getBid()
        socket.current = new WebSocket(`${SOCKET_BASE_URL}/ws/bid/${BID_ID}?token=${token}`)
        socket.current.onmessage = handleSocketData
        socket.current.onclose = handleSocketClose
        getServiceItems()
        handleColumnSettingToggle()
    }, [])

    const handleColumnSettingToggle = () => {
        const processedProfiles = getProcessedColumnProfiles()
        setColumns(getColumns(processedProfiles))
        setColumnRightStickyCount(processedProfiles.filter(col=>col.sticky).length)
    }

    const handleCellChanged = (changes) => {
        const change = changes[0]
        localCellChangeHandler(change)
        customCellChangeHandler(change)
    } 

    const localCellChangeHandler = ({ columnId, newCell, previousCell, rowId, type }) => {
        const expandChanged = newCell.isExpanded != previousCell.isExpanded
        const valueOrTextChanged = newCell.text != previousCell.text || newCell.value != previousCell.value

        if (valueOrTextChanged) {
            const newRows = [...rows];
            const changeRowIdx = rows.findIndex((el) => el.rowId === rowId);
            const changeColumnIdx = columns.findIndex(
              (el) => el.columnId === columnId
            );
            newRows[changeRowIdx].cells[changeColumnIdx] = newCell;
            setRows(newRows);
        }

        if (expandChanged) {
            store.localData.expantions[rowId] = newCell.isExpanded
        }

        if (newCell.isOpen) {
            store.localData.openedDropdownCell.rowId = rowId
            store.localData.openedDropdownCell.columnId = columnId
        } else {
            store.localData.openedDropdownCell.rowId = null
            store.localData.openedDropdownCell.columnId = null
        }
        setRowsFromBid()
    }

    const customCellChangeHandler = ({ columnId, newCell, previousCell, rowId }) => {
        if (newCell.text != previousCell.text) {
            const modelType = rowId.split("-")[0]
            const modelId = rowId.split("-")[1]
            if (newCell.text.startsWith("=")) {
                newCell.value = tryParseFormula(newCell.text)
            }

            let value

            const isTextCell = newCell.type == "customChevron" || newCell.type == "text" || newCell.type == "dropdown"
            const isNumberCell = newCell.type == "simpleFormula" || newCell.type == "number"
            const isCheckboxCell = newCell.type == "checkbox"

            if (isNumberCell) {
                value = isNaN(newCell.value) ? 0 : newCell.value
            } else if (isTextCell) {
                value = newCell.text
                if (newCell.type == "dropdown" && columnId == "is_makers_fees") {
                    value = newCell.text == "Internal"
                }
            } else if (isCheckboxCell) {
                value = newCell.text == "1"
            }

            if (modelType == LINE_ITEM_ROW_ID_PREFIX){
                updateLineItem(modelId, columnId, value)
            } else if (modelType == LINE_GROUP_ROW_ID_PREFIX) {
                updateLineGroup(modelId, columnId, value)
            } else if (modelType == ADDON_ROW_ID_PREFIX) {
                updateAddon(modelId, columnId, value)
            }
        }
    }

    // TODO: consider other approaches of ordering: decimal ordering, linked list etc
    const handleRowsReorder = (targetRowId, rowIds) => {        
        const lineItems = Object.fromEntries(store.bid.lineItems.map(line=>[line.id, line]))
        const lineGroups = Object.fromEntries(store.bid.lineGroups.map(group=>[group.id, group]))

        const [sourceIdPrefix, sourceIdString] = rowIds[0].split("-")
        const [_targetIdPrefix, targetIdString] = targetRowId.split("-")
        const sourceId = parseInt(sourceIdString)
        const targetId = parseInt(targetIdString)

        const isOrderingLine = sourceIdPrefix == LINE_ITEM_ROW_ID_PREFIX
        const isOrderingGroup = sourceIdPrefix == LINE_GROUP_ROW_ID_PREFIX

        let sourceParentId
        let targetParentId

        let sourceSiblings = []
        let targetSiblings = []

        let overallSourceIndex
        let overallTargetIndex

        if (isOrderingLine) {
            sourceParentId = lineItems[sourceId].line_group_id
            targetParentId = lineItems[targetId].line_group_id
            sourceSiblings = lineGroups[sourceParentId].line_items
            targetSiblings = lineGroups[targetParentId].line_items
            overallSourceIndex = store.bid.lineItems.map(l=>l.id).indexOf(sourceId)
            overallTargetIndex = store.bid.lineItems.map(l=>l.id).indexOf(targetId)
        } else if (isOrderingGroup) {
            sourceParentId = lineGroups[sourceId].parent_id
            targetParentId = lineGroups[targetId].parent_id
            sourceSiblings = sourceParentId ? lineGroups[sourceParentId].children : store.bid.line_items_grouped
            targetSiblings = targetParentId ? lineGroups[targetParentId].children : store.bid.line_items_grouped
            overallSourceIndex = store.bid.lineGroups.map(l=>l.id).indexOf(sourceId)
            overallTargetIndex = store.bid.lineGroups.map(l=>l.id).indexOf(targetId)
        }

        const isSortingDownwards = overallSourceIndex < overallTargetIndex
        const sameParent = sourceParentId == targetParentId

        const targetSiblingIds = targetSiblings.map(item=>item.id)
        const sourceSiblingIds = sourceSiblings.map(item=>item.id)

        const newSourceSiblingIds = sourceSiblingIds.filter(id=>id!=sourceId)
        if (sameParent) {
            const targetIndex = newSourceSiblingIds.indexOf(targetId)
            newSourceSiblingIds.splice(isSortingDownwards ? targetIndex + 1 : targetIndex, 0, sourceId)
            orderChildren(sourceParentId, newSourceSiblingIds)
        } else {
            orderChildren(sourceParentId, newSourceSiblingIds)
            const targetIndex = targetSiblingIds.indexOf(targetId)
            targetSiblingIds.splice(isSortingDownwards ? targetIndex + 1 : targetIndex, 0, sourceId)
            orderChildren(targetParentId, targetSiblingIds)
        }
    }
    
    const handleLocalRowsReorder = (targetRowId, rowIds) => {
        setRows((prevRows) => {
            const to = rows.findIndex(row => row.rowId === targetRowId);
            const rowsIds = rowIds.map((id) => rows.findIndex(row => row.rowId === id));
            return reorderArray(prevRows, rowsIds, to);
        })
    }

    const handleCanReorderRows = (targetRowId, rowIds) => {
        // TODO: check for leaf groups instead
        if (rowIds.length > 1) {
            return
        } 
        const rowsHash = rowsToHashTable(rows)
        return rowIds.every(rowId => rowsHash[rowId].indent == rowsHash[targetRowId].indent)
    }

    const handleSelectionChanged = (ranges) => {
        // NOTE: invokes on both mouse up and down
        handleSelectionSummary(ranges) // TODO: Isolate state
        handeSelectionHighlight(ranges)
    }

    const handeSelectionHighlight = (ranges) => {
        const selection = ranges[0].first
        sendSocketMessage(
            {
                type: "highlight",
                data: {rowId: selection.row.rowId, columnId: selection.column.columnId, borderColor: highlightColor}
            }
        )
    }

    const handleSelectionSummary = (ranges) => {
        let selectedSum = 0
        let selectedCount = 0
        let selectedNumCount = 0
        let selectedAverage = 0
        ranges.forEach(range=>{
            const colIdxStart = range.columns[0].idx
            const colIdxEnd = range.columns[range.columns.length-1].idx + 1
            range.rows.forEach(row=>{
                const selectedCells = row.cells.slice(colIdxStart, colIdxEnd)
                selectedCells.forEach(cell=>{
                    if (!isNaN(cell.value)) {
                        selectedSum += parseFloat(cell.value)
                        selectedNumCount += 1
                    }
                    selectedCount += 1 
                })
            })
        })
        selectedAverage = selectedSum / selectedNumCount
        setSumDataRef.current && setSumDataRef.current({selectedSum: selectedSum.toFixed(2), selectedCount, selectedAverage: selectedAverage.toFixed(2)})
    }

    const handleToggleExpandAll = () => {
        const nowExpandAll = !expandAll
        store.bid.lineGroups.map(group=>{
            store.localData.expantions[`group-${group.id}`] = nowExpandAll
        })
        setExpandAll(nowExpandAll)
        setRowsFromBid()
    }

    const handleColumnToggleVisibility = (id, isVisible) => {
        columnProfiles.find(col=>col.id == id).visible = isVisible
        setRowsFromBid()
        handleColumnSettingToggle()
    }

    const handleColumnToggleSticky = (id, isSticky) => {
        columnProfiles.find(col=>col.id == id).sticky = isSticky
        setRowsFromBid()
        handleColumnSettingToggle()
    }

    const handleExpandRow = (groupRowId, expanded) => {
        store.localData.expantions[groupRowId] = expanded
        setRowsFromBid()
    }

    const handleOpenLineGroupMenu = (groupRowId, anchor) => {
        setLineGroupMenuAnchor(anchor.current)
        const groupId = parseInt(groupRowId.split("-")[1])
        setLineGroupPopupData(store.bid.lineGroups.find(group=>group.id==groupId))
    }

    const handleSectionLinkClick = (sectionId) => {
        const rowIndex = rows.findIndex(row=>row.rowId == `group-${sectionId}`)
        const rowHeightPixel = rowIndex * ROW_CELL_HEIGHT - ROW_CELL_HEIGHT
        if (reactGridRef.current) {
            reactGridRef.current.scrollIntoView({
                behavior: "smooth",
            })
            reactGridRef.current.scrollTo({
                behavior: "smooth",
                top: rowHeightPixel
            })
        }
    }

    const handleDeleteRow = (rowId) => {
        // TODO: implement async waiting feedback
        const [ rowTypePrefix, modelId ] = rowId.split("-")
        if (rowTypePrefix == ADDON_ROW_ID_PREFIX) {
            request.delete(`bids/${BID_ID}/addons/${modelId}`)
        } else if (rowTypePrefix == LINE_ITEM_ROW_ID_PREFIX) {
            request.delete(`bids/${BID_ID}/line-items/${modelId}`)
        } else if (rowTypePrefix == LINE_GROUP_ROW_ID_PREFIX) {
            request.delete(`bids/${BID_ID}/line-groups/${modelId}`)
        }
    }

    const handleCopyRow = (rowId) => {
         // TODO: implement async waiting feedback
        const [ rowTypePrefix, modelId ] = rowId.split("-")
        if (rowTypePrefix == ADDON_ROW_ID_PREFIX) {
            const addonData = store.bid.addons.find(addon=>addon.id == modelId)
            request.post(`bids/${BID_ID}/addons`, addonData)
        } else if (rowTypePrefix == LINE_ITEM_ROW_ID_PREFIX) {
            const lineItemData = store.bid.lineItems.find(line=>line.id == modelId)
            request.post(`bids/${BID_ID}/line-items`, lineItemData)
        } else if (rowTypePrefix == LINE_GROUP_ROW_ID_PREFIX) {
            request.post(`bids/${BID_ID}/line-groups/${modelId}/copy`)
        }
    }

    const handleCleanBid = () => {
        request.put(`/bids/${BID_ID}/clean`)
    }

    const handleExport = () => {
        downloadFile(`bids/${BID_ID}/csv-export`)
    }

    return (
    <div>
        {store.bid.id && <BidHeader />}
        <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{marginBottom: 2}}>
            <Grid item>
                <Grid container alignItems={"center"} spacing={2}>
                    <Grid item paddingRight={4}>
                        <h4>Line Items</h4>
                    </Grid>
                    <Grid item>
                        <Button
                            endIcon={expandAll ? <UnfoldLessRoundedIcon/> : <UnfoldMoreRoundedIcon />}
                            onClick={handleToggleExpandAll}
                            color="secondary" size="small"
                            variant="contained"
                        >
                            {`${!expandAll ? "Expand" : "Collapse"} all`}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleCleanBid} endIcon={<CancelPresentationRoundedIcon/>} color="secondary" size="small" variant="contained">
                            Clean up empty lines
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={()=>{setColumnMenuOpen(true)}} ref={columnMenuAnchor} endIcon={<ViewWeekOutlinedIcon/>} color="secondary" size="small" variant="contained">Columns</Button>
                        <ColumnsMenu
                            isOpen={columnMenuOpen}
                            close={()=>{setColumnMenuOpen(false)}}
                            anchorEl={columnMenuAnchor.current}
                            onToggleSticky={handleColumnToggleSticky}
                            onToggleVisibility={handleColumnToggleVisibility}
                        />
                    </Grid>
                    <Grid item>
                        <Button onClick={handleExport} endIcon={<DownloadOutlinedIcon />} color="secondary" size="small" variant="contained">Export</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <HighlightsOverview registerHighlightsDataFunction={registerChildHighlightsFunction} />
            </Grid>
        </Grid>
        <div ref={reactGridRef} style={{width: "100%", overflowX: "auto", maxHeight: 500, overflowY: "auto", marginBottom: 50}}>
            <ReactGrid
                rows={rowsToRender}
                columns={columns}
                onCellsChanged={handleCellChanged}
                onRowsReordered={handleRowsReorder}
                canReorderRows={handleCanReorderRows}
                onSelectionChanged={handleSelectionChanged}
                enableRowSelection
                stickyLeftColumns={2}
                stickyRightColumns={columnRightStickyCount}
                stickyTopRows={1}
                enableRangeSelection
                customCellTemplates={{
                    simpleFormula: new FormulaCellTemplate(),
                    customChevron: new CustomChevronCellTemplate(handleExpandRow, handleOpenLineGroupMenu, handleCopyRow, handleDeleteRow)
                }}
            />
        </div>

        {(canViewConfidentialData && store.bid.id) && 
        <div>
            <BidNotes
                bidId={BID_ID}
                readOnly={readOnly}
            />
            
            <AddonsForm
                addons={rootAddons}
            />
            <Divider sx={{marginTop: 6, marginBottom: 2}}/>
            {!isTemplate &&
            <BidBills
                bidId={BID_ID}
                legacy={store.bid.project.legacy_bills}
            />}
            {!isTemplate && <Grid container spacing={8} sx={{marginBottom: 6}}>
                {canViewConfidentialData ? (
                    <Grid item md={8}>
                        <Comments comments={store.bid.comments} modelType={"bid"} modelInstanceId={BID_ID} />
                    </Grid>
                ) : null}
                <Grid item md={4}>
                    {store.bid.activity.map(activity => (
                        <BidActivity key={activity.id} {...activity} />
                    ))}
                </Grid>
            </Grid>}
        </div>}

        {store.bid.id && 
            <BidSum 
                registerSumDataFunction={registerChildSumDataFunction} 
                isTemplate={false} 
                onSectionLinkClick={handleSectionLinkClick}
            />
        }
        <LineGroupMenu 
            anchorEl={lineGroupMenuAnchor}
            lineGroupData={lineGroupPopupData}
            close={()=>{setLineGroupMenuAnchor(null)}}
        />
    </div>
    )
}

const ColumnsMenu = ({isOpen, close, anchorEl, onToggleVisibility, onToggleSticky}) => {
    const controllableColumns = columnProfiles.filter(col=>!col.alwaysVisible)
    return (
        <Menu open={isOpen} anchorEl={anchorEl} onClose={close}>
            {controllableColumns.map(col=>
            <MenuItem key={col.id} >
                <ColumnMenuItem 
                    id={col.id}
                    defaultSticky={col.sticky}
                    defaultVisible={col.visible}
                    label={col.headerText}
                    onToggleVisibility={onToggleVisibility}
                    onToggleSticky={onToggleSticky}
                />
            </MenuItem>
            )}
        </Menu>
    )
}

const ColumnMenuItem = ({id, label, defaultVisible, defaultSticky, onToggleVisibility, onToggleSticky}) => {
    const [ hidden, setHidden ] = useState(defaultVisible)
    const [ sticky, setSticky ] = useState(defaultSticky)
    useEffect(()=>{
        if (!defaultVisible) {
            columnProfiles.find(col=>col.id == id).visible = defaultVisible
            columnProfiles.find(col=>col.id == id).sticky = defaultSticky
        }
    }, [])
    const handleToggleVisibility = () => {
        const nowHidden = !hidden
        setHidden(nowHidden)
        onToggleVisibility(id, nowHidden)
    }
    const handleToggleSticky = () => {
        const nowSticky = !sticky
        setSticky(nowSticky)
        onToggleSticky(id, nowSticky)
    }
    return (
        <Grid container alignItems={"center"} fontSize={14} justifyContent={"space-between"}>
            <Grid item><span>{label}</span></Grid>
            <Grid item>
                <Grid container>
                    <Grid item>
                        <IconButton size="small" onClick={handleToggleVisibility}>
                            {hidden ? <VisibilityOutlinedIcon fontSize="tiny"/> : <VisibilityOffOutlinedIcon fontSize="tiny"/>}
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton size="small" onClick={handleToggleSticky}>
                            {sticky ? <PushPinIcon fontSize="tiny" /> : <PushPinOutlinedIcon fontSize="tiny"/>}
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}