import { Autocomplete, Grid, IconButton, ListItem, TextField, Tooltip } from "@mui/material"
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { useState } from "react";
import { serviceItems } from "./serviceItemsStore";
import { request } from "@/Api";
import { getOfficeCurrency } from "./bidStore";
import { countryCurrencies } from "@/constants";
import { getLineItemRate } from "@/bid/bidSlice";
import { BID_SECTION_PRODUCTION_SERVICES, BID_SECTION_TALENT, BID_SECTION_TRAVEL } from "@/sheet/constants";

export const AddButtonCell = ({ type, parent, serviceItemOptions }) => {
    const [ adding, setAdding ] = useState(false)
    const [ inputValue, setInputValue ] = useState("")
    const [ highlightedValue, setHighlightedValue ] = useState("")

    const handleInputKeyDown = (e) => {
        e.stopPropagation()
    }
    const handleAddButtonClick = (e) => {
        setAdding(true)
    }
    const handleCancelButtonClick = (e) => {
        setAdding(false)
        setInputValue("")
        setHighlightedValue("")
    }

    const getSectionDefaultMarkup = (sectionName) => {
        if ([BID_SECTION_TALENT, BID_SECTION_TRAVEL].includes(sectionName)){
            if (sectionName == BID_SECTION_TRAVEL && project.client.name.includes("Pepsi")){
                return 0.1
            }
            return 0.15
        }else if (sectionName == BID_SECTION_PRODUCTION_SERVICES){
            return 0
        }else{
            return 0.3
        }
    }

    const addLine = (value) => {
        const officeCurrency = getOfficeCurrency()
        const baseData = serviceItems.find(item =>
            item.name == value && (item.category==parent.name || (parent.is_makers_fees && item.category == BID_CATEGORY_PRODUCTION_SERVICES))
        ) || {name: value}
        request.post(`/bids/${parent.bid_id}/line-items`, {
            ...baseData, 
            bid_id: parent.bid_id,
            line_group_id: parent.id,
            service_item_id: baseData.id || null,
            vendor_currency: baseData.country_code ? countryCurrencies[baseData.country_code] : officeCurrency || "CAD",
            vendor_rate : getLineItemRate(0, baseData, parent.rate_type),
            markup: parent.markup,
            rate_type: parent.rate_type,
            sort_order: parent.line_items.length + 1,
            days: parent.rate_type == "Flat rate" ? 1 : 0,
            units: 0
        })
    }

    const addCategory = (value) => {
        request.post(`/bids/${parent.bid_id}/line-groups`, {
            name: value,
            is_makers_fees: parent.is_makers_fees,
            rate_type: parent.rate_type,
            markup: parent.markup,
            sort_order: parent.children.length + 1,
            bid_id: parent.bid_id,
            parent_id: parent.id,
        })
    }

    const addSection = (value) => {
        request.post(`/bids/${parent.id}/line-groups`, {
            name: value,
            markup: getSectionDefaultMarkup(value),
            is_makers_fees: false,
            bid_id: parent.id,
            sort_order: parent.line_items_grouped.length + 1,
            rate_type: "Days",
            lineGroupType: "section",
            popupType: "create"
        })
    }

    const addItem = (value) => {
        if (type == "line") {
            addLine(value)
        } else if (type == "category") {
            addCategory(value)
        } else if (type == "section") {
            addSection(value)
        }
    }

    return (            
        <Grid container alignItems={"center"} wrap="nowrap">
            <Grid item>
                {!adding &&
                <IconButton
                    size="small"
                    onClick={handleAddButtonClick}
                >
                    <AddCircleRoundedIcon color="success" fontSize="tiny"/>
                </IconButton>}
            </Grid>
            <Grid item>
                {adding &&
                    <IconButton
                        size="small"
                        onClick={handleCancelButtonClick}
                    >
                        <RemoveCircleRoundedIcon fontSize="tiny"/>
                    </IconButton>
                }
            </Grid>
            <Grid item width={"100%"}>
                {adding &&
                    <Autocomplete
                        style={{padding: 0}}
                        value={inputValue}
                        disableClearable
                        freeSolo
                        size="small"
                        renderInput={(params) => 
                            <TextField 
                                placeholder={`add ${type}`} 
                                autoFocus {...params} 
                                onPointerDown={(e)=>{e.stopPropagation()}}
                                inputProps={{...params.inputProps, style: {fontSize: 12, border: "none", padding: 2, paddingLeft: 6}}}
                                InputProps={{...params.InputProps, style: {padding: 0, fontSize: 12}}}
                            />
                        }
                        onKeyDown={handleInputKeyDown}
                        options={serviceItemOptions}
                        onPointerDown={(e)=>{e.stopPropagation()}}
                        onChange={(e, v)=>{setInputValue(v)}}
                        onHighlightChange={(e, v)=>{setHighlightedValue(v)}}
                        onKeyPress={(e) => {e.key!="Enter" || addItem(highlightedValue || e.target.value)}}
                        renderOption={(props, option, state) => (
                            <ListItem
                                style={{padding: 5, paddingLeft: 10, fontSize: 12}}
                              {...props}
                              onPointerDown={(event) => event.stopPropagation()} // Custom pointer down handler for each option
                            >
                              {option}
                            </ListItem>
                        )}
                    />
                }
            </Grid>
        </Grid>
    )
}