import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams, useParams } from "react-router-dom"
import { Helmet } from "react-helmet"
import { parse, format } from "date-fns"
import styled from 'styled-components'

import { Chip, Tooltip, useTheme } from "@mui/material"
import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FilledInput from '@mui/material/FilledInput'
import Switch from '@mui/material/Switch'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import Link from '@mui/material/Link'
import Snackbar from "@mui/material/Snackbar"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'

import ProjectLoading from '@/project/ProjectLoading'
import ProjectBillsLegacy from '@/project/ProjectBillsLegacy'
import ProjectBills from '@/project/ProjectBills'
import ApiAutocomplete from "@/common/ApiAutocomplete"
import ProjectForecast from "@/project/ProjectForecast"
import FilesUploader from "@/common/FilesUploader"
import ProjectChecklist from '@/project/ProjectChecklist'
import ProjectBidCreate from '@/project/ProjectBidCreate'
import ProjectBids from "@/project/ProjectBids"
import Comments from "@/common/Comments"
import { Subtitle } from '@/project/StyledComponents'
import { Title, Form, BoxButton, HighlightedIcon } from '@/common/StyledComponents'
import { request } from "@/Api"
import * as project_constants from "@/project/constants"
import { ACCOUNTING_STRATEGY, RESOURCING_STRATEGY, STORAGE_STRATEGY, currencies } from "@/constants"
import { getCurrencySymbol } from "@/utils"
import { openBidPopup } from '@/nav/navSlice'
import { FLOAT_BASE_URL } from '@/config'
import ProjectMilestones from '@/project/ProjectMilestones'
import { BID_STATUS_APPROVED, BID_STATUS_SIGNED } from '@/sheet/constants'
import StrategyComponents from '@/common/StrategyComponents'
import { selectHasPermission } from '@/auth/authSlice'
import ProjectFlow from '@/project_common/ProjectFlow'
import { useSetOrgId } from '@/common/hooks'

const ProjectContainer = styled.div`
    padding-bottom: 150px;
`

export default function Project(){
    const { projectId } = useParams()

    useSetOrgId()
    
    const [searchParams, setSearchParams] = useSearchParams()
    const defaultMilestoneId = searchParams.get("milestone")

    const theme = useTheme()
    const dispatch = useDispatch()

    const [project, setProject] = useState({})
    const [cachedFieldValue, setCachedFieldValue] = useState(null)
    const [bids, setBids] = useState([]) // TODO: consider removing this state cause otherwise we might have to manage project.bids as well
    const [error, setError] = useState("")
    const [errors, setErrors] = useState({})
    const [isBidRefreshing, setIsBidRefreshing] = useState(false)
    const [showSaved, setShowSaved] = useState(false)
    const [showBillAlert, setShowBillAlert] = useState(false)
    const [statuses, setStatuses] = useState({})

    const canUpdate = useSelector(state => selectHasPermission(state, "edit_projects"))
    const [updatePermission, setUpdatePermission] = useState(canUpdate)
    const updateGoogleIds = useSelector(state => selectHasPermission(state, "view_admin_panel"))
    const filesPermission = useSelector(state => selectHasPermission(state, "post_files"))
    const billsPermission = useSelector(state => selectHasPermission(state, "post_bills"))
    const confidentialDataPermission = useSelector(state => selectHasPermission(state, "view_confidential_data"))
    const projectStatusFlowPermission = useSelector(state => selectHasPermission(state, "project_status_flow"))
    const multiLegalEntityPermission = useSelector(state => selectHasPermission(state, "multi_legal_entity"))

    const bidFiles = project.bids ? project.bids.reduce((files, bid)=>{
        files = files.concat(bid.files)
        return files
    }, []) : []
    
    const [showReadOnlyAlert, setShowReadOnlyAlert] = useState(!updatePermission)

    const getStatuses = () => {
        request.get("statuses")
        .then((response) => {
            setStatuses(response.data.reduce((obj, status) => (obj[status.name] = status, obj) ,{}))
        })
    }

    const refreshProjectBidData = () => { 
        setIsBidRefreshing(true)

        request.post(`projects/${project.id}/refresh-gs-request`)
        .then((response)=>{
            setProject(mapProject(response.data))
        })
        .finally((() => {
            setIsBidRefreshing(false)
        }))
    }

    const mapProject = (newProject) => {
        return {
            ...newProject,
            person_ids: newProject.persons.map((person)=>person.id), // for multi autocomplete
        }
    }

    const getProject = () => {
        request.get(`projects/${projectId}`)
        .then((response) => {
            setProject(mapProject(response.data))
            setBids(response.data.bids)

            if (updatePermission && response.data.access_level != "write") {
                setUpdatePermission(false)
                setShowReadOnlyAlert(true)
            }
        })
        .catch(function (error) {
            setUpdatePermission(false)
            if (error.response?.status == 403){
                setError("You do not have access to view this project.")
            } else {
                setError("Failed to load project, please contact an admin.")
            }
        })
    } 

    const handleBillsChange = (bills) => {
        setProject({...project, bills: [...bills]})
    }

    const handleInputChange = (fieldName) => (event) => {
        setProject({...project, [fieldName]: event.target.value})
    }

    const handleCalendarChange = (fieldName, save) => (newValue) => {
        let date = newValue
        if (date != null) {
            try {
                date = format(newValue, "y-MM-dd")
            } catch (e) {
                date = ""
            }
        }

        setProject({...project, [fieldName]: date})

        if (save) {
            saveProject({[fieldName]: date})
        }
    }

    const handleSwitchChange = (fieldName) => (event) => {
        const checked = event.target.checked
        if (fieldName == "signed" && checked){
            if (project.bills.length == 0){
                setShowBillAlert(true)
                return
            }
        }
        const data = fieldName == "signed" ? {
            [fieldName]: checked,
            status_id: checked ? statuses[project_constants.PROJECT_STATUS_IN_PRODUCTION].id : statuses[project_constants.PROJECT_STATUS_BRIEFED].id,
        } : {[fieldName]: checked}
        saveProject(data)
    }

    const handleTeamMemberChange = (event, persons) => {
        const person_ids = persons.map((person)=>person.id)
        const personsClone = persons.map((person)=>{return {...person}})
        setProject({...project, person_ids: person_ids, persons: personsClone})
        saveProject({"person_ids": person_ids})
    }

    const handleAutocompleteChange = (fieldName, idFieldName) => (event, newValue) => {
        let updatedData = null
        if (idFieldName){
            if (typeof newValue == "object"){    
                updatedData = {[fieldName]: newValue, [idFieldName]: newValue.id}
            }
            else if (typeof newValue == "number"){
                updatedData = {[idFieldName]: newValue}
            }
        } else {
            updatedData = {[fieldName]: newValue}
        }
        if (updatedData){  
            setProject({...project, ...updatedData})
            saveProject({...updatedData})
        }
    }

    useEffect(()=>{
        getProject()
        getStatuses()
        setErrors({})  
    }, [])

    useEffect(()=>{
        getProject()
        setErrors({}) 
    }, [projectId])
    
    const handlePopupOpen = () => {
        dispatch(openBidPopup({
            type: "Bid Sheet",
            project: project,
        }))
    }

    const cacheProjectField = (field) => () => {
        setCachedFieldValue(project[field])
    }

    const saveProjectField = (field) => () => {
        if (cachedFieldValue != project[field]) {
            saveProject({ [field]: project[field] })
        }
        setCachedFieldValue(null)
    }

    const saveProject = (data) => {
        setErrors({})
        
        request.patch(`projects/${project.id}`, data).then((response)=>{
            setProject(mapProject(response.data))
            setShowSaved(true)
        }).catch(function (error) {
            if (error.response.status == 422){
                const details = error.response.data.detail
                const currentErrors = {}
                for (var i in details){
                    const field = details[i].loc[details[i].loc.length-1]
                    const msg = details[i].msg
                    if (details[i].loc[1] == "forecast_bills") {
                        if (!currentErrors.forecast_bills) {
                            currentErrors.forecast_bills = {}
                        }
                        const index = details[i].loc[2]
                        currentErrors.forecast_bills[index] = {
                            ...currentErrors.forecast_bills[index],
                            [field]: msg,
                        }
                    } else {
                        currentErrors[field] = msg
                    }
                }
                setErrors(currentErrors)
            } else {
                setError(error.response?.data?.detail || "Failed to update project.")
            }
        })
    }

    const setProjectSigned = (files) => {
        const signedStatusId = statuses.find((status) => project_constants.PROJECT_STATUS_IN_PRODUCTION == status.name).id
        setProject({...project, signed: true, status_id: signedStatusId, files: [...files, ...project.files]})
    }

    const onStatusUpdate = (statusId) => {
        setProject({...project, status_id: statusId})
        saveProject({status_id: statusId})
    }

    return Object.keys(project).length == 0 ? <ProjectLoading /> : (
        <ProjectContainer>
            <Helmet><title>{project.name} - Makers Central</title></Helmet>

            <Subtitle>
                <HighlightedIcon icon={WorkOutlineOutlinedIcon} theme={theme} highlighted />
                <h4>{project.docket}</h4>
            </Subtitle>
                
            <TextField
                value={project.name || ""}
                onChange={handleInputChange("name")}
                onFocus={cacheProjectField("name")}
                onBlur={saveProjectField("name")}
                placeholder="Project Name"
                variant="standard"
                InputProps={{
                    style: {fontSize: "36px"},
                    readOnly: !updatePermission,
                    disableUnderline: true,
                }}
                InputLabelProps={{style: {fontSize: "36px"}}}
                fullWidth
            />

            <Form>    
                    <ProjectFlow
                        onStatusUpdate={onStatusUpdate}
                        statuses={statuses}
                        project={project}
                        mode={"project"}
                    >
                        <Grid container spacing={2} wrap='nowrap'>
                            { updateGoogleIds ? (
                                <Grid item>
                                    <TextField
                                        label="Project Folder ID"
                                        value={project.google_drive_folder_id || ""}
                                        onChange={handleInputChange("google_drive_folder_id")}
                                        onFocus={cacheProjectField("google_drive_folder_id")}
                                        onBlur={saveProjectField("google_drive_folder_id")}
                                        fullWidth
                                        InputProps={{
                                            readOnly: !updatePermission,
                                        }}
                                    />
                                </Grid>
                            ) : null }
                            <Grid item sx={{marginTop: "16px"}}>
                                <StrategyComponents strategy={STORAGE_STRATEGY}>
                                    {project.google_drive_folder_id ?
                                    <Link
                                        target={"_blank"}
                                        href={`https://drive.google.com/drive/folders/${project.google_drive_folder_id}`}
                                        color="inherit"
                                        sx={{textWrap: "nowrap"}}
                                    >
                                        Project Folder <OpenInNewIcon sx={{ verticalAlign: "middle", marginLeft: '6px' }} />
                                    </Link> : "Missing Project Folder"}
                                </StrategyComponents>
                            </Grid>
                        </Grid>
                        
                    </ProjectFlow>

                <Title bold>Details</Title>

                <Grid container spacing={2} sx={{ marginBottom: "48px" }}>
                    <Grid item xs={12} md={12}>
                        <TextField 
                            placeholder='Project Summary' 
                            value={project.description || ""} 
                            onChange={handleInputChange("description")}
                            onFocus={cacheProjectField("description")}
                            onBlur={saveProjectField("description")}
                            fullWidth 
                            multiline 
                            rows={2}
                            InputProps={{
                                readOnly: !updatePermission,
                            }}
                        />
                    </Grid>
                
                    <Grid item xs={6} md={4}>
                        <InputLabel>Client</InputLabel>
                        <ApiAutocomplete
                            value={project.client}
                            apiRoute={"clients"}
                            fields={["name"]}
                            isOptionEqualToValue={(option, value) => option.id == value.id}
                            onChange={handleAutocompleteChange("client", "client_id")}
                            error={errors["client_id"]}
                            readOnly={!updatePermission}
                            fullOptionData
                            blurOnSelect
                        />
                    </Grid> 
                    <Grid item xs={6} md={4}>
                        <InputLabel>Client Owner (EP)</InputLabel>
                        <TextField 
                            fullWidth
                            disabled
                            value={project.client.owner ? `${project.client.owner.first_name} ${project.client.owner.last_name}` : ""}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <InputLabel>Producer</InputLabel>
                        <ApiAutocomplete
                            readOnly={!updatePermission}
                            value={project.owner}
                            apiRoute={"persons"}
                            fields={["first_name", "last_name"]}
                            isOptionEqualToValue={(option, value) => option.id == value.id}
                            onChange={handleAutocompleteChange("owner", "owner_id")}
                            error={errors["owner_id"]}
                            fullOptionData
                            blurOnSelect
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <InputLabel>Project Type</InputLabel>
                        { project?.project_type?.archived_at ? (
                            <TextField
                                value={project?.project_type.name || ""}
                                fullWidth
                                readOnly
                            />
                        ) : (
                            <ApiAutocomplete
                                readOnly={!updatePermission}
                                value={project.project_type}
                                apiRoute={"project-types"}
                                fields={["name"]}
                                isOptionEqualToValue={(option, value) => option.id == value.id}
                                onChange={handleAutocompleteChange("project_type_id", "project_type_id")}
                                error={errors["project_type_id"]}
                                fullOptionData
                                blurOnSelect
                            />
                        ) }
                    </Grid>
                    {multiLegalEntityPermission &&
                    <Grid item xs={6} md={4}>
                        <InputLabel>Entity</InputLabel>
                        <ApiAutocomplete
                            readOnly={!updatePermission}
                            value={project.legal_entity}
                            apiRoute={"legal-entities"}
                            isOptionEqualToValue={(option, value) => option.id == value.id}
                            fields={["name"]}
                            onChange={handleAutocompleteChange("legal_entity", "legal_entity_id")}
                            error={errors["legal_entity_id"]}
                            fullOptionData
                            blurOnSelect
                        />
                    </Grid>}
                    <Grid item xs={6} md={4}>
                        <InputLabel>Project Currency</InputLabel>
                        <Autocomplete
                            readOnly={!updatePermission}
                            value={project.currency}
                            disablePortal
                            options={currencies}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={handleAutocompleteChange("currency")}
                            blurOnSelect
                        />
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid item xs={6} md={4}>
                            <InputLabel>Start Date</InputLabel>
                            <DatePicker
                                readOnly={!updatePermission}
                                value={project.start_date ? parse(project.start_date, "y-MM-dd", new Date()) : null}
                                inputFormat={"yyyy-MM-dd"}
                                onChange={handleCalendarChange("start_date", false)}
                                onAccept={handleCalendarChange("start_date", true)}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        error={"start_date" in errors}
                                        helperText={errors["start_date"]}
                                        onFocus={cacheProjectField("start_date")}
                                        onBlur={saveProjectField("start_date")}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <InputLabel>End Date</InputLabel>
                            <DatePicker
                                readOnly={!updatePermission}
                                value={project.end_date ? parse(project.end_date, "y-MM-dd", new Date()) : null}
                                inputFormat={"yyyy-MM-dd"}
                                onChange={handleCalendarChange("end_date", false)}
                                onAccept={handleCalendarChange("end_date", true)}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        error={"end_date" in errors}
                                        helperText={errors["end_date"]}
                                        onFocus={cacheProjectField("end_date")}
                                        onBlur={saveProjectField("end_date")}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <InputLabel>Live Date</InputLabel>
                            <DatePicker
                                readOnly={!updatePermission}
                                value={project.live_date ? parse(project.live_date, "y-MM-dd", new Date()) : null}
                                inputFormat={"yyyy-MM-dd"}
                                onChange={handleCalendarChange("live_date", false)}
                                onAccept={handleCalendarChange("live_date", true)}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        error={"live_date" in errors}
                                        helperText={errors["live_date"]}
                                        onFocus={cacheProjectField("live_date")}
                                        onBlur={saveProjectField("live_date")}

                                    />
                                )}
                            />
                        </Grid>
                    </LocalizationProvider>
                    <Grid item xs={6} md={12}>
                        <InputLabel>Team Members</InputLabel>
                        <ApiAutocomplete
                            readOnly={!updatePermission}
                            value={project.persons ? project.persons : []}
                            apiRoute={"persons"}
                            fields={["first_name", "last_name"]}
                            isOptionEqualToValue={(option, value) => option.id == value.id}
                            onChange={handleTeamMemberChange}
                            error={errors["person_ids"]}
                            multiple
                            fullOptionData
                        />
                    </Grid>
                </Grid>

                <Title bold>Financials</Title>

                <Grid container spacing={2} sx={{ marginBottom: "48px" }} wrap={"nowrap"}>    
                    <Grid item flexGrow={1}>
                        <FormControl fullWidth variant="filled">
                            <InputLabel>Bid Quoted</InputLabel>
                            <FilledInput
                                sx={{borderRadius:"0px"}}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                value={project.quoted ? project.quoted.toLocaleString() : 0}
                                readOnly
                            />
                        </FormControl>
                    </Grid>
                    {confidentialDataPermission ? (
                        <>
                            <Grid item flexGrow={1}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel>Bid Hard Costs</InputLabel>
                                    <FilledInput
                                        sx={{borderRadius:"0px"}}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        value={project.hard_cost ? project.hard_cost.toLocaleString() : 0}
                                        readOnly
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item flexGrow={1}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel>Bid GP</InputLabel>
                                    <FilledInput
                                        sx={{borderRadius:"0px"}}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        value={project.gross_profit ? project.gross_profit.toLocaleString() : 0}
                                        readOnly
                                        />
                                </FormControl>
                            </Grid>
                            <Grid item flexGrow={1}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel>Running Total</InputLabel>
                                    <FilledInput
                                        sx={{borderRadius:"0px"}}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        value={project.running_total ? project.running_total.toLocaleString() : 0}
                                        readOnly
                                        />
                                </FormControl>
                            </Grid>
                            <Grid item flexGrow={1}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel>Internal Budget</InputLabel>
                                    <FilledInput
                                        sx={{borderRadius:"0px"}}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        value={project.internal_budget ? project.internal_budget.toLocaleString() : 0}
                                        readOnly
                                    />
                                </FormControl>
                            </Grid>
                            <StrategyComponents strategy={RESOURCING_STRATEGY}>
                                <Grid item flexGrow={1}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel>Internal Actual</InputLabel>
                                        <FilledInput
                                            sx=
                                            {
                                                project.float_internal_actual > project.internal_budget ?
                                                {borderRadius:"0px", "::before": {content: "none"}, "::after": {content: "none"}, borderBottom:"red solid 1px"}:
                                                {borderRadius:"0px"}
                                            }
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            value={project.float_internal_actual ? project.float_internal_actual.toLocaleString() : 0}
                                            readOnly
                                        />
                                    </FormControl>
                                </Grid>
                            </StrategyComponents>
                        </>
                    ) : null}
                        <StrategyComponents strategy={ACCOUNTING_STRATEGY}>
                            <Tooltip title="* old & new numbers" placement="top">
                                <Grid item flexGrow={1}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel>Billed {<span style={{fontSize: 14, paddingLeft: 3}}>{project.accounting_rev_tmp.toLocaleString()} *</span>} </InputLabel>
                                        <FilledInput
                                            sx={{borderRadius:"0px"}}
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            value={project.accounting_rev ? project.accounting_rev.toLocaleString() : 0}
                                            readOnly
                                        />
                                    </FormControl>
                                </Grid>
                            </Tooltip>
                            <Tooltip title="* old & new numbers" placement="top">
                            {confidentialDataPermission ?
                                <Grid item flexGrow={1}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel>Spent {<span style={{fontSize: 14, paddingLeft: 3}}>{project.accounting_cost_tmp.toLocaleString()} *</span>}</InputLabel>
                                        <FilledInput
                                            sx={{borderRadius:"0px"}}
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            value={project.accounting_cost ? project.accounting_cost.toLocaleString() : 0}
                                            readOnly
                                        />
                                    </FormControl>
                                </Grid> : null}
                            </Tooltip>
                        </StrategyComponents>
                </Grid>

                <ProjectForecast
                    forecast={{
                        forecast_revenue: project.forecast_revenue,
                        forecast_schedule: project.forecast_schedule,
                        forecast_certainty: project.forecast_certainty,
                        forecast_bills: project.forecast_bills || []
                    }}
                    setForecast={(forecast) => setProject({
                        ...project,
                        ...forecast,
                    })}
                    errors={{
                        forecast_revenue: errors.forecast_revenue,
                        forecast_schedule: errors.forecast_schedule,
                        forecast_certainty: errors.forecast_certainty,
                        forecast_bills: errors.forecast_bills || {}
                    }}
                    setErrors={(forecastErrors) => setErrors({
                        ...errors,
                        ...forecastErrors,
                    })}
                    currency={getCurrencySymbol(project.currency || "CAD")}
                    cacheForecastField={cacheProjectField}
                    saveForecastField={saveProjectField}
                    saveForecast={saveProject}
                    showSaveAlert={() => setShowSaved(true)}
                    project_id={projectId}
                    defaultExpanded={project.confirmed_bid == null}
                    readOnly={!updatePermission}
                />

                <Title bold>Bills {project.legacy_bills ? ' (legacy, requires manual saving)' : ''}</Title>

                {project.legacy_bills ? (
                    <ProjectBillsLegacy
                        projectQuoted={project.bid_quoted}
                        currency={project.currency}
                        projectId={project.id}
                        bills={project.bills || []}
                        disabled={!billsPermission || !updatePermission}
                        setProjectBills={handleBillsChange}
                    />
                ) : (
                    <ProjectBills
                        bills={project.bills || []}
                        setProjectBills={handleBillsChange}
                        currency={project.currency}
                        disabled={!billsPermission || !updatePermission}
                        showSaveAlert={() => setShowSaved(true)}
                    />
                )}
            </Form>

            {confidentialDataPermission && projectStatusFlowPermission ? (
                <>
                    <Divider sx={{marginTop: 5, marginBottom: 5}}/>
                    <ProjectChecklist 
                        title={"Pre-bid Checklist"}
                        descriptions={[
                            <span>Book your resources in <Link target="_blank" href={`${FLOAT_BASE_URL}?project=${encodeURIComponent(project.docket + " " + project.name)}`}>Float</Link></span>,
                            <span>Go through the <Link target="_blank" href="https://contracts.makers.to">Insurance Checklist</Link></span>,
                            <span>Consider the <Link target="_blank" href="http://sustainability.makers.to">Sustainability Practices</Link></span>,
                        ]}
                    />
                </>
            ) : null}

            <Divider sx={{marginTop: 5, marginBottom: 5}}/>
            <Grid container justifyContent={"space-between"}>
                <Grid item>
                    <Title bold>Bid Sheets</Title>
                </Grid>
                {confidentialDataPermission ? project.legacy_bid ? (
                    <Grid item container columnSpacing={3}>
                        <Grid item>
                            {isBidRefreshing ? <CircularProgress/> : null }
                        </Grid>
                        <Grid item>
                            <Button onClick={refreshProjectBidData} disabled={isBidRefreshing}>Refresh Bid Data</Button>
                        </Grid>
                        <Grid item>
                            <ProjectBidCreate project={project} />
                        </Grid>
                    </Grid>
                ) : bids.length > 0  ? (
                    <Grid container item spacing={1}>
                        <Grid item>                        
                            <StrategyComponents strategy={ACCOUNTING_STRATEGY}>
                                <Tooltip title={project.confirmed_bid ? "" : "Your bid needs to be signed to start actualizing"}>
                                    <span>
                                        <Chip
                                            onClick={()=>{window.location = `/projects/${projectId}/actuals`}}
                                            variant='outlined'
                                            label="Actualize"
                                            clickable
                                            disabled={!bids.some(bid => bid.status==BID_STATUS_SIGNED)}
                                        />
                                    </span>
                                </Tooltip>
                            </StrategyComponents>
                        </Grid>
                        <Grid item>
                            <ProjectBidCreate project={project} />
                        </Grid>
                    </Grid>
                ) : null : null}
            </Grid>

            {bids.length > 0 ? (
                <>
                    <ProjectBids
                        bids={bids}
                        confirmedBid={project.confirmed_bid}
                        setBids={setBids}
                        setProjectSigned={setProjectSigned}
                        lockMainBid={project.confirmed_bid != null && project.confirmed_bid.status != BID_STATUS_APPROVED}
                        projectCurrency={project.currency}
                        confidentialDataPermission={confidentialDataPermission}
                        updatePermission={updatePermission}
                    />
                    <StrategyComponents strategy={STORAGE_STRATEGY}>
                        <Grid spacing={3} container>
                            <Grid item xs={12}>
                                <FilesUploader disabled files={project.files.concat(bidFiles) || []} modelId={project.id} modelType={"project"} />
                            </Grid>
                        </Grid>
                    </StrategyComponents>
                </>
            ) : project.legacy_bid ? (
                <>
                    {project.google_drive_bid_file_id == "n/a" ? (
                    <>There's no bid sheet associated with this project. Let ops team know if you think it's a mistake.</>
                    ) : !project.google_drive_bid_file_id  ? (
                        <>Your project folder might still be creating, try refreshing in a few seconds :)</>
                    ) : null }
                
                    <Grid spacing={3} container>
                        <Grid item xs={12} md={6}>
                            <Subtitle>Details</Subtitle>
                            <Grid container xs={12} md={12} justifyContent={"space-between"} alignItems={"center"}>
                                <Grid padding={0}>
                                    <InputLabel>Approved by EP?</InputLabel>
                                </Grid>
                                <Grid>
                                    <Switch
                                        disabled={ !updatePermission }
                                        checked={project.ep_approved ? true : false}
                                        onChange={handleSwitchChange("ep_approved")}
                                    />
                                </Grid>
                                <Divider sx={{width: "100%"}}/>
                                <Grid padding={0}>
                                    <InputLabel>Signed by Client?</InputLabel>
                                </Grid>
                                <Grid>
                                    <Switch
                                        disabled={ !updatePermission }
                                        checked={project.signed ? true : false}
                                        onChange={handleSwitchChange("signed")}
                                    />
                                </Grid>
                                
                                <Divider sx={{width: "100%"}}/>
                                
                                {updateGoogleIds ? (
                                    <>
                                        <Grid item paddingLeft={0}>
                                            <InputLabel>Bid Sheet</InputLabel>
                                        </Grid>
                                        <Grid item flexGrow={1}>
                                            <TextField
                                                value={project.google_drive_bid_file_id || ""}
                                                onChange={handleInputChange("google_drive_bid_file_id")}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Divider sx={{width: "100%"}} />
                                    </>
                                ) : null }
                                
                                {project.google_drive_bid_file_id ? (
                                    <Grid paddingLeft={0}>
                                        <Link target={"_blank"} href={`https://docs.google.com/spreadsheets/d/${project.google_drive_bid_file_id}`} color="inherit">
                                            Bid Sheet on Google <OpenInNewIcon sx={{ verticalAlign: "middle", marginLeft: '6px' }} />
                                        </Link>
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Grid>
                        <StrategyComponents strategy={STORAGE_STRATEGY}>
                            <Grid item xs={12} md={6}>
                                <FilesUploader files={project.files || []} modelId={project.id} modelType={"project"} disabled={ !filesPermission } />
                            </Grid>
                        </StrategyComponents>
                    </Grid>
                </>
            ) : updatePermission ? (
                <Grid container spacing={5} padding={3} justifyContent="center">
                    <Grid item md={3}>
                        <BoxButton
                            theme={theme}
                            text="Create a bid sheet"
                            icon={<IntegrationInstructionsIcon />}
                            onClick={handlePopupOpen}
                        />
                    </Grid>
                </Grid>
            ) : (
                <p>None</p>
            )}

            {confidentialDataPermission && projectStatusFlowPermission ? (
                <div style={{marginBottom: 50}}>
                    <Divider sx={{marginTop: 5, marginBottom: 5}}/>
                    <ProjectChecklist 
                        title={"Production Checklist"}
                        descriptions={[
                            <span>Ensure you complete the billing schedule once client signed estimate has been uploaded.</span>,
                            <span>Ensure your partners and vendors are set up for payment through Tipalti. (Spend over 30k requires a PO)</span>,
                            <span>If you have a priority invoice that needs to be paid quickly, please complete our <Link target='_blank' href='https://docs.google.com/spreadsheets/d/1xqCGCgwrJ4c1LFThK8Sfa8w5GZsD1Q27DFDnpJq_HK4/edit#gid=0'>Rush Payment Request Form</Link></span>
                        ]}
                    />
                </div>
            ) : null}

            <ProjectMilestones
                defaultMilestone={project.milestones.find(milestone=>milestone.id==defaultMilestoneId)}
                projectId={projectId}
                milestones={project.milestones}
                setMilestones={(milestones)=>{setProject({...project, milestones: milestones})}}
                readOnly={!updatePermission}
            />

            {confidentialDataPermission ? (
                <>
                    <Divider sx={{marginTop: 5, marginBottom: 5}}/>
                    <Title bold>Comments</Title>
                    <Grid container>
                        <Grid item md={8}>
                            <Comments comments={project.comments || []} modelType={"project"} modelInstanceId={project.id}/>
                        </Grid>
                    </Grid>
                </>
            ) : null}

            <Snackbar
                open={showSaved}
                autoHideDuration={2000}
                onClose={(event, reason) => {setShowSaved(false)}}
                message="Project saved"
            />

            <Snackbar
                open={showBillAlert}
                autoHideDuration={2000}
                onClose={(event, reason) => {setShowBillAlert(false)}}
            >
                <Alert severity="error">Please create bill schedule first</Alert>
            </Snackbar>

            {confidentialDataPermission ? <Snackbar open={showReadOnlyAlert}>
                <Alert
                    severity="warning"
                    variant="filled"
                    onClose={() => setShowReadOnlyAlert(false)}
                >
                    You are restricted to read only access for this project.
                </Alert>
            </Snackbar> : null}

            <Snackbar open={error != ""} anchorOrigin={{vertical: "top", horizontal: "center" }}>
                <Alert
                    severity="error"
                    variant="filled"
                    onClose={() => setError("")}
                >
                    {error}
                </Alert>
            </Snackbar>            
        </ProjectContainer>
    )
}
