import { uuidv4 } from "@/utils"
import { ADD_BUTTON_CELL_HEIGHT, ADDON_ROW_ID_PREFIX, LINE_GROUP_ROW_ID_PREFIX, LINE_ITEM_ROW_ID_PREFIX, RESOURCE_TYPES, ROW_CELL_HEIGHT } from "./constants"
import { getRow , headerRow } from "./sheetUtils"
import { AddButtonCell } from "./AddButtonCell"
import { getServiceItemsByCategory } from "./serviceItemsStore"
import { currencies } from "@/constants"
import { BID_STATUS_DRAFT, bidCategories, bidSections, UNIT_TYPES } from "@/sheet/constants"
import { ProfileListItem } from "@/profiles_common/CommonComponents"

import SortRoundedIcon from '@mui/icons-material/SortRounded';
import LocalAtmRoundedIcon from '@mui/icons-material/LocalAtmRounded';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import AddAlarmRoundedIcon from '@mui/icons-material/AddAlarmRounded';
import MovingRoundedIcon from '@mui/icons-material/MovingRounded';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import { values } from "draft-js/lib/DefaultDraftBlockRenderMap"

export const store = {
    bid: {}, 
    localData: {
        expantions: {},
        openedDropdownCell: {
            rowId: null,
            columnId: null
        },
    }
}

var option = {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
}

const percentageFormatter = new Intl.NumberFormat("en-US", option)

export const columnProfiles = [
    {id: "", type: "text", width: 10, renderer: () => <span style={{cursor: "pointer"}}>≡</span>, alwaysVisible: true},
    {id: "name", headerIcon: <SortRoundedIcon fontSize="tiny" style={{paddingLeft: 32}}/>, headerText: "Name", type: "customChevron", width: 300, alwaysVisible: true},
    
    // {id: "id", type: "text", alwaysVisible: true},
    // {id: "sort_order", type: "number", alwaysVisible: true},

    {id: "is_makers_fees", headerIcon: <LocalAtmRoundedIcon fontSize="tiny"/>, headerText: "Resource Type", type: "dropdown", visible: true, values: RESOURCE_TYPES.map(r => {return {value: r, label: r}})},    
    {id: "vendor_currency", headerIcon: <LocalAtmRoundedIcon fontSize="tiny"/>, headerText: "Currency", type: "dropdown", values: currencies.map(c => {return {value: c, label: c}}), visible: true},
    {id: "vendor_rate", headerIcon: <LocalOfferOutlinedIcon fontSize="tiny"/>, headerText: "Rate", type: "simpleFormula", visible: true},
    {id: "rate_type", headerIcon: <AccessTimeRoundedIcon fontSize="tiny"/>, headerText: "Unit Type", type: "dropdown", values: UNIT_TYPES.map(u => {return {value: u, label: u}}), visible: true},
    {id: "units", headerIcon: <ShoppingCartOutlinedIcon fontSize="tiny"/>, headerText: "Qty", type: "simpleFormula", visible: true}, // quantity
    {id: "days", headerIcon: <PeopleOutlineRoundedIcon fontSize="tiny"/>, headerText: "Units", type: "simpleFormula", visible: true}, // this is now the multiplier because we have a dynamic unit, we just have a hard-coded key
    {id: "ot_1_5", headerIcon: <AddAlarmRoundedIcon fontSize="tiny"/>, headerText: "OT 1.5", type: "simpleFormula", visible: true},
    {id: "ot_2_0", headerIcon: <AddAlarmRoundedIcon fontSize="tiny"/>, headerText: "OT 2.0", type: "simpleFormula", visible: true},
    {id: "markup", headerIcon: <MovingRoundedIcon fontSize="tiny"/>, headerText: "Markup", type: "simpleFormula", format: percentageFormatter},
    {id: "adjustment", headerIcon: <BuildOutlinedIcon fontSize="tiny"/>, headerText: "Adjustments", type: "simpleFormula"},
    {id: "internal_notes", headerIcon: <NoteAltOutlinedIcon fontSize="tiny"/>, headerText: "Notes", type: "text"},
    //{id: "client_quoted_margin", headerIcon: <LocalAtmRoundedIcon fontSize="tiny"/>, headerText: "Margin", type: "number", nonEditable: true, visible: true, format: Intl.NumberFormat("en-US", {style: "percent",})},
    //{id: "client_gross_profit", headerIcon: <LocalAtmRoundedIcon fontSize="tiny"/>, headerText: "GP", type: "number", nonEditable: true, visible: true},
    {id: "client_budget", headerIcon: <PaymentsOutlinedIcon fontSize="tiny"/>, headerText: "Budget", type: "number", nonEditable: true, style: {color: "#CB7F0E", background: "#FFF7EA"}, sticky: true, visible: true},
    {id: "client_quoted", headerIcon: <PaidOutlinedIcon fontSize="tiny"/>, headerText: "Quoted", type: "number", nonEditable: true, style: {color: "#148451", background: "#D4F3E5"}, sticky: true, alwaysVisible: true},
    
    // {id: "parent_id", type: "number"},
    // {id: "line_group_id", type: "number"},
    // {id: "depth", type: "number"},
    // {id: "internal_notes", type: "text"},
    // {id: "external_notes", type: "text"},
]

const fieldsSelectedValueMapping = {
    is_makers_fees: {
        true: "Internal",
        false: "External"
    }
}

export const traverseLineItems = (lineItemsGrouped) => {
    let lineItems = []
    let stack = lineItemsGrouped
    while (stack.length > 0) {
        const currentGroup = stack.pop()
        lineItems = [...lineItems, ...currentGroup.line_items]
        stack = [...stack, ...currentGroup.children]
    }
    return lineItems
}

export const traverseLineGroups = (lineItemsGrouped) => {
    let lineGroups = [...lineItemsGrouped]
    let stack = lineItemsGrouped
    while (stack.length > 0) {
        const currentGroup = stack.pop()
        lineGroups = [...lineGroups, ...currentGroup.children]
        stack = [...stack, ...currentGroup.children]
    }
    return lineGroups
}

export const traverseAddons = (lineItemsGrouped) => {
    let addons = []
    let stack = lineItemsGrouped
    while (stack.length > 0) {
        const currentGroup = stack.pop()
        addons = [...addons, ...currentGroup.addons]
        stack = [...stack, ...currentGroup.children]
    }
    return addons
}

const getAddButtonRow = ( type, parentGroup, parentGroupRowId, serviceItems, depth ) => {
    const processedColumnProfiles = getProcessedColumnProfiles()
    const addButtonHasChildren = false
    const addButtonColumnProfile = processedColumnProfiles.map((profile, index)=>profile.id=="name" ? {
        id: "name",
        type: "text", 
        nonEditable: true,
        alwaysVisible: true,
        renderer: () => 
        <div style={{paddingLeft: depth * 20 + 32, width: "100%"}}>
            <AddButtonCell 
                type={type}
                parent={parentGroup || store.bid}
                serviceItemOptions={serviceItems}
            />
        </div>
    } : {id: "", type: "text"})
    return getRow(
        {id: uuidv4(), depth: depth}, 
        addButtonColumnProfile, 
        addButtonHasChildren, 
        parentGroupRowId,
        depth,
        ADD_BUTTON_CELL_HEIGHT
    )
}

export const getProcessedColumnProfiles = () => {
    const colProfiles = []
    const stickyColProfiles = []
    columnProfiles.forEach(col=>{
        const collection = col.sticky ? stickyColProfiles : colProfiles
        if (col.visible || col.alwaysVisible) {
            collection.push(col)
        }
    })
    return [...colProfiles, ...stickyColProfiles]
}

// consider optimization: try to aggregate reactgrid from maintaining (instead of fully override) original data
// code clean up: break down this function to smaller functions
export const bidToRows = () => {
    const processedColumnProfiles = getProcessedColumnProfiles()
    let rows = []
    let stack = [...store.bid.line_items_grouped, {addButton: "section", depth: 0, parent_id: null}].reverse()
    let lastGroups = []
    while (stack.length > 0) {
        const currentGroup = stack.pop()
        const currentGroupHasChildren = true
        const groupRowId = `${LINE_GROUP_ROW_ID_PREFIX}-${currentGroup.id}`
        const groupParentRowId = currentGroup.parent_id && `${LINE_GROUP_ROW_ID_PREFIX}-${currentGroup.parent_id}`
        const lineItemHasChildren = false
        const addonHasChildren = false
        const currentGroupDepth = currentGroup.depth || 0
        const nextGroupDepth = currentGroupDepth + 1

        if (currentGroup.addButton) {
            const parentGroup = store.bid.lineGroups.find(group=>group.id==currentGroup.parent_id)
            if (parentGroup) {
                parentGroup.addons.forEach(addon=>{
                    const addonParentId = addon.line_group_id && `${LINE_GROUP_ROW_ID_PREFIX}-${addon.line_group_id}`
                    const addonRowId = `${ADDON_ROW_ID_PREFIX}-${addon.id}`
                    rows.push(getRow(
                        {...addon, depth: nextGroupDepth, id: addonRowId}, 
                        processedColumnProfiles, 
                        addonHasChildren, 
                        addonParentId, 
                        currentGroupDepth,
                        ROW_CELL_HEIGHT,
                        null,
                    ))
                })
            }
    
            rows.push(getAddButtonRow(
                currentGroup.addButton,
                parentGroup,
                groupParentRowId,
                currentGroup.addButton == "section" ? bidSections : bidCategories,
                currentGroupDepth
            ))
            continue
        }

        rows.push(getRow(
            {...currentGroup, id: groupRowId}, 
            processedColumnProfiles, 
            currentGroupHasChildren, 
            groupParentRowId, 
            currentGroup.depth,
            ROW_CELL_HEIGHT,
            store.localData.expantions[groupRowId],
            store.localData.openedDropdownCell,
            false,
            fieldsSelectedValueMapping
        ))

        currentGroup.line_items.forEach(line=>{
            const lineItemParentId = line.line_group_id && `${LINE_GROUP_ROW_ID_PREFIX}-${line.line_group_id}`
            const lineItemRowId = `${LINE_ITEM_ROW_ID_PREFIX}-${line.id}`
            rows.push(getRow(
                {...line, depth: nextGroupDepth, id: lineItemRowId}, 
                processedColumnProfiles, 
                lineItemHasChildren, 
                lineItemParentId, 
                nextGroupDepth,
                ROW_CELL_HEIGHT,
                null,
                store.localData.openedDropdownCell,
                false,
                fieldsSelectedValueMapping
            ))
        })

        if (currentGroup.line_items.length > 0 || currentGroup.depth >= 1) {
            currentGroup.addons.forEach(addon=>{
                const addonParentId = addon.line_group_id && `${LINE_GROUP_ROW_ID_PREFIX}-${addon.line_group_id}`
                const addonRowId = `${ADDON_ROW_ID_PREFIX}-${addon.id}`
                rows.push(getRow(
                    {...addon, depth: nextGroupDepth, id: addonRowId}, 
                    processedColumnProfiles, 
                    addonHasChildren, 
                    addonParentId, 
                    nextGroupDepth,
                    ROW_CELL_HEIGHT,
                    null,
                ))
            })
            const serviceItemNames = getServiceItemsByCategory(currentGroup.name).map(item=>item.name)
            rows.push(getAddButtonRow("line", currentGroup, groupRowId, serviceItemNames, nextGroupDepth))
        }

        let childrenWithDepthInfo = [...currentGroup.children.map(group=>{return {...group, depth: nextGroupDepth}})]
        if (!currentGroup.parent_id) {
            childrenWithDepthInfo = [{addButton: "category", depth: nextGroupDepth, parent_id: currentGroup.id}, ...childrenWithDepthInfo]
        }
        stack = stack.concat(childrenWithDepthInfo)
        if (childrenWithDepthInfo.length > 0){
            lastGroups.push(childrenWithDepthInfo[0])
        }
    }
    const header = headerRow(processedColumnProfiles)
    return [header, ...rows]
}

export const storeBid = (bid) => {
    store.bid = bid
    store.bid.lineItems = traverseLineItems([...bid.line_items_grouped])
    store.bid.lineGroups = traverseLineGroups([...bid.line_items_grouped])
    // store.bid.addons = traverseAddons([...bid.line_items_grouped])

    const {
        producer_fee_quoted_percentage,
        producer_fee_quoted,
        gross_profit_margin,
        client_gross_profit,
        net_profit_margin,
        client_net_profit,
        insurance,
        client_hard_cost_quoted,
        client_quoted,
        client_hard_cost_budget,
        client_mark_up,
        new_net_profit,
        net_profit_percentage,
        project,
        client_budget,
        office_budget,
        client_marked_up_fee,
        office_marked_up_fee,
        adjustment,
        office_adjustment,
        office_gross_profit,
        office_net_profit,
        office_quoted,
    } = bid

    store.bid.project_rd_bid_ids = []

    store.bid.insights = {
        producer_fee_quoted_percentage,
        producer_fee_quoted,
        gross_profit_margin,
        client_gross_profit,
        net_profit_margin,
        client_net_profit,
        insurance,
        
        client_hard_cost_quoted,
        client_quoted,
        client_hard_cost_budget,
        client_mark_up,
        new_net_profit,
        net_profit_percentage,

        project_producer_fee_quoted_target_percentage: project.producer_fee_quoted_target_percentage,
        project_producer_fee_quoted_target: project.producer_fee_quoted_target,
        project_producer_fee_quoted_percentage: project.producer_fee_quoted_percentage,
        project_producer_fee_quoted: project.producer_fee_quoted,
        project_hard_cost_quoted: project.hard_cost_quoted,
        project_quoted: project.quoted,
        project_quoted_target: project.quoted_target,
        project_hard_cost_budget: project.hard_cost_budget,
        project_mark_up: project.mark_up,
        project_new_net_profit: project.new_net_profit,
        project_net_profit_percentage: project.net_profit_percentage,
        project_net_profit_target: project.net_profit_target,
        project_net_profit_target_percentage: project.net_profit_target_percentage,
    }
    
    store.bid.insightGoals = {
        producer_fee_quoted_goal: bid.producer_fee_quoted_goal,
        gross_profit_goal: bid.gross_profit_goal,
        net_profit_goal: bid.net_profit_goal,
        default_insurance: bid.default_insurance,
    }

    store.bid.totals = {
        client_budget,
        office_budget,
        client_marked_up_fee,
        office_marked_up_fee,
        adjustment,
        office_adjustment,
        client_gross_profit,
        office_gross_profit,
        client_net_profit,
        office_net_profit,
        client_quoted,
        office_quoted,
    }

    store.bid.top_sheet_sender_ids = bid.top_sheet_senders.map((sender)=>{return sender.id})
}

// Setters

export const addLineItem = (id, lineItem) => {
    bid.lineItems[id] = lineItem
    bid.lineGroups[lineItem.line_group_id].push(lineItem) 
}

export const addLineGroup = (id, lineGroup) => {
    bid.lineGroup[id] = lineGroup
    if (lineGroup.parent_id) {
        bid.lineGroup[lineGroup.parent_id].children.push(lineGroup)
    }
}

export const updateLineItem = (id, lineItem) => {
    const line = bid.lineItems[id]
    Object.entries(lineItem).map((k, v) => {
        line[k] = v
    })
}

export const updateLineGroup = (id, lineGroup) => {
    const group = bid.lineGroups[id]
    Object.entries(lineGroup).map((k, v) => {
        lineGroup[k] = v
    })
}

export const getOfficeCurrency = () => {
    return store.bid.project?.legal_entity.country.currency || "CAD"
}

export const getClientCurrency = () => {
    return store.bid.project?.currency || "CAD"
}

export const getReadOnly = () => {
    return store.bid.status != BID_STATUS_DRAFT
}

export const getRootAddons = () => {
    if (!store.bid.addons) {
        return []
    }
    return store.bid.addons.filter(addon=>!addon.line_group_id)
}