import styled from 'styled-components'

import { useTheme } from "@mui/material"

import { formattedCurrency } from "@/utils"
import { getClientCurrency, store } from "@/sheet/bidStore"
import { useEffect, useState } from 'react'


const Container = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin: auto;
    z-index: 3;
    font-size: 12px;
    background: ${props => props.theme.palette.background.paper};
    position: sticky;
    bottom: 30px;
    padding: 20px 40px;
    border-radius: 16px;
    box-shadow: 0px 0px 50px 0px #00000040;
`
const SectionLinks = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
`
const SectionLink = styled.div`
    padding: 2px 20px;
    border-left: 1px solid #dddddd;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    white-space: nowrap;
    
    &:first-child {
        padding-left: 0px;
        border-left: none;
    }
`
const Detail = styled.div`
    padding: 0px 24px;
    text-align: ${props => props.textAlign || "left"};
    flex-grow: ${props => props.flexGrow || 0};
    overflow-x: auto;
    
    h3 {
        font-weight: 400;
        font-size: 10px;
        margin: 0 0 6px 0;
        position: sticky;
        left: 0;
        top: 0;
    }

    p {
        font-weight: 600;
        font-size: 12px;
        margin: 0;
    }
`
const SelectedSum = styled.div`
    background: ${props => props.theme.palette.background.paper};
    position: absolute;
    top: -45px;
    right: 0px;
    padding: 8px;
    border-radius: 6px;
    box-shadow: 0px 0px 30px 0px #00000040;
`

export default function BidSum({ registerSumDataFunction, isTemplate, onSectionLinkClick }) {
    const [ sumData, setSumData ] = useState({})
    
    const theme = useTheme()

    const totals = store.bid.totals
    const clientCurrency = getClientCurrency()
    const sections = store.bid.line_items_grouped

    const selectedSum = formattedCurrency(sumData.selectedSum || 0)
    // const selectedAverage = formattedCurrency(((!sumData.selectedAverage || isNaN(sumData.selectedAverage)) ? 0 : sumData.selectedAverage))
    // const selectedCount = sumData.selectedCount || 0

    if (isTemplate == undefined){
        return
    }

    useEffect(() => {
        registerSumDataFunction(setSumData)
    }, [])

    return (
        <Container theme={theme}>
            <Detail flexGrow="1">
                <h3>Sections</h3>
                <SectionLinks>
                    {sections.map(section => (
                        <SectionLink key={section.id} onClick={() => onSectionLinkClick(section.id)}>{section.name}</SectionLink>
                    ))}
                </SectionLinks>
            </Detail>

            <Detail textAlign="right" >
                <h3>Budget</h3>
                <p>{formattedCurrency(totals.client_budget, clientCurrency)}</p>
            </Detail>

            <Detail textAlign="right">
                <h3>Total</h3>
                <p>{formattedCurrency(totals.client_quoted, clientCurrency)}</p>
            </Detail>

            {selectedSum != 0 && (
                <SelectedSum theme={theme}>
                    {`Sum: ${selectedSum}`}
                </SelectedSum>
            )}
        </Container>
    )
}
