import * as React from "react";
import {
  isNavigationKey,
  getCellProperty,
  isAlphaNumericKey,
  keyCodes
} from "@silevis/reactgrid";
import "./customCellStyle.css"
import { Grid } from "@mui/material";
import { useState } from "react";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { useRef } from "react";
import { useEffect } from "react";

export default class CustomChevronCellTemplate {
    constructor (expandGroup, openLineGroupMenu, openCopyConfirmation, openDeleteConfirmation) {
        this.openLineGroupMenu = openLineGroupMenu
        this.expandGroup = expandGroup
        this.openCopyConfirmation = openCopyConfirmation
        this.openDeleteConfirmation = openDeleteConfirmation
    }
    
    getCompatibleCell(uncertainCell) {
        const text = getCellProperty(uncertainCell, "text", "string");
        const value = parseFloat(text);
        return { ...uncertainCell, text, value };
    }

    handleKeyDown(
        cell,
        keyCode,
        ctrl,
        shift,
        alt
    ){
        if (!ctrl && !alt && isAlphaNumericKey(keyCode))
            return { cell, enableEditMode: true };
        return {
            cell,
            enableEditMode: keyCode == keyCodes.POINTER || keyCode == keyCodes.ENTER
        }
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    render(cell, isInEditMode, onCellChanged = (cell, commit) => {}) {
        return (
            <CustomCheveronCell 
                cell={cell}
                isInEditMode={isInEditMode}
                expandGroup={this.expandGroup}
                openLineGroupMenu={this.openLineGroupMenu}
                onCellChanged={onCellChanged}
                getCompatibleCell={this.getCompatibleCell}
                copyRow={this.openCopyConfirmation}
                deleteRow={this.openDeleteConfirmation}
            />
        )
    }
}

const CustomCheveronCell = ({cell, isInEditMode, expandGroup, openLineGroupMenu, onCellChanged, getCompatibleCell, copyRow, deleteRow}) => {
    return (
        <div style={{width: "100%"}}>
            {isInEditMode ? 
                <CustomChevronCellEditMode 
                    cell={cell} 
                    onCellChanged={onCellChanged}
                    getCompatibleCell={getCompatibleCell}
                /> : 
                <CustomChevronCellReadMode 
                    cell={cell} 
                    expandGroup={expandGroup}
                    openLineGroupMenu={openLineGroupMenu}
                    copyRow={copyRow}
                    deleteRow={deleteRow}
                />
            }
        </div>
    )
}

const CustomChevronCellReadMode = ({cell, expandGroup, openLineGroupMenu, copyRow, deleteRow}) => {
    const [ pointerOver, setPointerOver ] = useState(false)
    const menuRef = useRef() 

    const handleExpand = () => {
        const nowExpanded = !cell.isExpanded
        expandGroup(cell.rowId, nowExpanded)
    }

    const handleOpenMenu = (e) => {
        e.stopPropagation()
        e.preventDefault()
        openLineGroupMenu(cell.rowId, menuRef)
    }

    const handleCopy = (e) => {
        copyRow(cell.rowId)
    }

    const handleDelete = (e) => {
        deleteRow(cell.rowId)
    }

    return (
        <div
            style={{width: "100%"}}
            onPointerEnter={()=>{setPointerOver(true)}}
            onPointerLeave={()=>{setPointerOver(false)}}
        >
            <Grid container justifyContent={"space-between"} wrap="nowrap">
                <Grid item>
                    <Grid container alignItems={"center"} wrap="nowrap">
                        <Grid item width={32} marginLeft={cell.indent * 3}>
                        {pointerOver && 
                            <Grid container wrap="nowrap">
                                <Grid item>
                                    <DeleteOutlineRoundedIcon
                                        fontSize="tiny"
                                        onClick={handleDelete}
                                        style={{cursor: "pointer", color: "grey"}}
                                    />
                                </Grid>
                                <Grid item paddingRight={1}>
                                    <ContentCopyRoundedIcon
                                        fontSize="tiny"
                                        onClick={handleCopy}
                                        style={{cursor: "pointer", color: "grey"}}
                                    />
                                </Grid>
                            </Grid>
                        }
                        </Grid>
                        <Grid item maxWidth={165} overflow={"auto"}>
                            <span style={{paddingRight: 10}}>{cell.text}</span>
                        </Grid>
                        {cell.hasChildren && 
                            <Grid item ref={menuRef} height={"12px"}>
                                <MoreVertIcon
                                    fontSize="tiny"
                                    onClick={handleOpenMenu}
                                    style={{cursor: "pointer"}}
                                />
                            </Grid>
                            }
                        {cell.rowId.startsWith("addon") &&
                        <Grid item>
                            <LocalOfferOutlinedIcon
                                fontSize="tiny"
                            />
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item>
                    {cell.hasChildren && 
                    <ArrowForwardIosRoundedIcon 
                        fontSize="tiny" 
                        onClick={handleExpand}
                        style={{cursor: "pointer", transform: cell.isExpanded && "rotate(90deg)"}}
                    />}
                </Grid>
            </Grid>
        </div>
    )
}

const CustomChevronCellEditMode = ({cell, onCellChanged, getCompatibleCell}) => {
    return (
        <input
            ref={(input) => {
                input && input.focus();
            }}
            defaultValue={cell.text}
            onChange={(e) =>
                onCellChanged(
                    getCompatibleCell({ ...cell, text: e.currentTarget.value }),
                    false
                )
            }
            onCopy={(e) => e.stopPropagation()}
            onCut={(e) => e.stopPropagation()}
            onPaste={(e) => e.stopPropagation()}
            onPointerDown={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
            if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
                e.stopPropagation();
            }}
            onPointerEnter={(e) => e.stopPropagation()}
            onBlur={(e) => 
                {
                    onCellChanged(
                        getCompatibleCell({ ...cell, text: e.currentTarget.value, value: parseFloat(e.currentTarget.value) }),
                        true
                    )
                }
            }
            style={{
                outline: "none",
                border: "none"
            }}
        />
    )
}