import { useEffect, useRef, useState } from "react"
import { Grid } from "@mui/material"
import { request } from "@/Api"
import { InfoInput } from "./TopSheetConfiguratorComponents"
import { IconButton } from "@mui/material"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { API_BASE_URL } from "@/config";
import { useDispatch } from "react-redux"
import { updateLegalEntity } from "../bid/bidSlice"

export default function TopSheetConfiguratorLegalEntity({legalEntity}){
    const dispatch = useDispatch()
    const [legalEntityLocal, setLegalEntityLocal] = useState({})
    const logoUploaderRef = useRef()

    useEffect(()=>{
        setLegalEntityLocal(legalEntity)
    }, [])

    const uploadTopSheetLogo = () => {
        const file = logoUploaderRef.current.files[0]
        if (!file) {
            return
        }
        const formData = new FormData()
        formData.append("file", file, file.name)
        formData.append("legal_entity_id", legalEntity.id)
        request.post(`legal-entities/${legalEntity.id}/logo-image`, formData)
        .then(response=>{
            const updatedLegalEntity = {...legalEntityLocal, "logo_id": response.data.logo_id, "logo": response.data.logo}
            setLegalEntityLocal(updatedLegalEntity)
            dispatch(updateLegalEntity(updatedLegalEntity))
        })
    }

    const handleInputBlur = () => {
        request.put(`/legal-entities/${legalEntity.id}`, {...legalEntityLocal})
        .then(response=>{
            setLegalEntityLocal(response.data)
            dispatch(updateLegalEntity(response.data))
        })
    }
    
    const handleInputChange = (field) => (event) => {
        setLegalEntityLocal({...legalEntityLocal, [field]: event.target.value})
    }

    return (
        <>
            <Grid container justifyContent={"space-between"} spacing={5}>
                <CompanyInfoGridItem title={"Logo"} onClick={()=>{logoUploaderRef.current.click()}}>
                    <CompanyInfoBidLogo image={legalEntityLocal.logo}/>
                    <input ref={logoUploaderRef} type="file" hidden onChange={uploadTopSheetLogo} accept="image/png, image/jpeg, image/webp"/>
                </CompanyInfoGridItem>
                <CompanyInfoGridItem title={"Address"}>
                    <InfoInput 
                        onBlur={handleInputBlur}
                        onInput={handleInputChange("address")}
                        rows={4}
                        multiline
                        size="small"
                        text={legalEntityLocal.address || ""}
                    />
                </CompanyInfoGridItem>
            </Grid>
        </>
    )
}

export const CompanyInfoBidLogo = ({image}) => {
    if (!image) {
        return null
    }
    const imageUrl = image.processed ? image.url_large : image.url_orig
    return(
        <img width={"100%"} src={imageUrl} style={{borderRadius: 6}}/>
    )
}

export const CompanyInfoGridItem = (props) => {
    const { title, onClick, children } = props

    return (
        <Grid item md={6} xs={12}>
            <Grid container alignItems={"center"} paddingBottom={1}>
                <Grid item color={"gray"}>
                    {title}
                </Grid>
                <Grid item>
                    <IconButton size="small" onClick={onClick}>
                        <EditOutlinedIcon fontSize="tiny"/>
                    </IconButton>
                </Grid>
            </Grid>
            {children}
        </Grid>
    )
}