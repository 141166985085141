import FormulaParser from "fast-formula-parser"

export const parser = new FormulaParser()
parser.data = []

parser.onCell = ({sheet, row, col}) => {
    return parser.data[row - 1][col - 1]
}
parser.onRange = (ref) => {
    const arr = [];
    for (let row = ref.from.row; row <= ref.to.row; row++) {
        const innerArr = [];
        if (parser.data[row - 1]) {
            for (let col = ref.from.col; col <= ref.to.col; col++) {
                innerArr.push(parser.data[row - 1][col - 1]);
            }
        }
        arr.push(innerArr);
    }
    return arr;
}

export const tryParseFormula = (val) => {
	if (typeof(val)=="string" && val.startsWith("=")){
		try {
			return parser.parse(val.slice(1))
		} catch (e) {
			console.log(e)
			return val
		}
	}
	else {
		return val
	}
} 