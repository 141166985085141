import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { format, formatDistanceToNow } from 'date-fns'
import styled from 'styled-components'

import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close'
import UpdateIcon from '@mui/icons-material/Update'
import DoneIcon from '@mui/icons-material/Done'
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import WorkIcon from '@mui/icons-material/Work';
import WalletIcon from '@mui/icons-material/Wallet';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import HistoryIcon from '@mui/icons-material/History';
import NumbersIcon from '@mui/icons-material/Numbers';
import BusinessIcon from '@mui/icons-material/Business';

import BidTitle from "@/sheet/BidTitle";
import BidInsights from "@/sheet/BidInsights";
import { InternalLink } from "@/common/CommonComponents"
import * as constants from "@/sheet/constants"
import { selectHasPermission, selectHasRole } from "@/auth/authSlice";
import BaseOrganizationComponents from "@/common/BaseOrganizationComponents";
import BidTemplatePublishingSwitch from "@/bid/BidTemplatePublishingSwitch";
import { request } from "@/Api"
import BidActionPopup from "@/sheet/BidActionPopup"
import ConfirmationDialog from "@/common/ConfirmationDialog"
import { BoldInline } from "@/common/StyledComponents"
import { TopSheetConfigurator } from "@/sheet/TopSheetConfigurator"
import { downloadTopsheet } from "@/sheet/TopsheetDownload"
import FilesUploader from "@/common/FilesUploader"
import { store } from "@/sheet/bidStore";


const CustomBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:30%;
    min-width: 500px;
    padding: 24px;
`
const IconText = styled.span`
    display: inline-block;
    vertical-align: top;
    padding: 5px 0 0 5px;
    font-size: 12px;
`

export default function BidHeader() {
    const theme = useTheme()

    const [ actionOpen, setActionOpen ] = useState(false)
    const [ actionType, setActionType ] = useState("")
    const [ cancelOpen, setCancelOpen ] = useState(false)
    const [ downloadForSignatureOpen, setDownloadForSignatureOpen ] = useState(false)
    const [ requestFormOpen, setRequestFormOpen ] = useState(false)
    const [ confirmingSigned, setConfirmingSigned ] = useState(false)
    const [ signedOpen, setSignedOpen ] = useState(false)
    const [ topConfigOpen, setTopConfigOpen ] = useState(false)
    const [ filesUploaderOpen, setFilesUploaderOpen ] = useState(false)
    const [ anchorEl, setAnchorEl ] = useState(null)

    const project = store.bid.project
    
    const multiLegalEntity = useSelector(state => selectHasPermission(state, "multi_legal_entity"))
    const canViewConfidentialData = useSelector(state => selectHasPermission(state, "view_confidential_data"))
    const canApproveBids = useSelector(state => selectHasPermission(state, "approve_bids"))
    const hasBidApprovalFlow = useSelector(state => selectHasPermission(state, "bid_approval_flow"))
    const isFinance = useSelector(state => selectHasRole(state, "finance"))

    const bidId = store.bid.id
    const bidName = store.bid.name
    const xodoHash = store.bid.xodo_document_hash
    const rdBidIds = store.bid.project_rd_bid_ids
    const status = store.bid.status
    const file = store.bid.files[store.bid.files.length-1]
    const signerCount = store.bid.signer_count
    const updatedAt = store.bid.updated_at
    const menuOpen = Boolean(anchorEl);

    const handleFileUploaded = (newFiles) => {
        submitSigned()
        setFilesUploaderOpen(false)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleOpenTopSheetConfig = () => {
        setTopConfigOpen(true)
        handleMenuClose()
    }

    const handleOpenRequestForm = () => {
        handleOpenTopSheetConfig()
        setRequestFormOpen(true)
    }
    
    const handleOpenFilesUploader = () => {
        setFilesUploaderOpen(true)
        handleMenuClose()
    }
    
    const handleAction = (action) => () => {
        setActionType(action)
        setActionOpen(true)
    }

    const handleCancel = () => {
        setCancelOpen(true)
    }
    const submitCancel = () => {
        request.post(`/bids/${bidId}/activity`, {action: constants.BID_ACTIVITY_CANCELLED, note: "Cancelled"})
        .then(response => {
            setCancelOpen(false)
        })
    }

    const submitDeleteBills = () => {
        for(const id of rdBidIds) {
            request.delete(`/bids/${id}/bills`)
        }
    }

    const submitSigned = () => {
        setConfirmingSigned(true)
        request.post(`/bids/${bidId}/activity`, {action: constants.BID_ACTIVITY_SIGNED, note: "Confirm client signed by uploading existing signature"})
        .then(response => {
            setConfirmingSigned(false)
        })
    }

    const downloadForSignature = () => {
        setAnchorEl(null)
        request.post(`/bids/${bidId}/activity`, {action: constants.BID_ACTIVITY_DOWNLOADED, note: "Final version of topsheet downloaded for signature, bid sheet locked"})
        .then(response => {
            downloadTopsheet(bidName)
        })
    }

    const getActionButtons = () => {
        const buttons = {
            topsheet: (
                <Button
                    sx={{background: "white", color: "black", fontSize:"10px"}}
                    variant="contained"
                    startIcon={<TuneRoundedIcon />}
                    onClick={handleOpenTopSheetConfig}
                >
                    Top Sheet
                </Button>
            ),
            signature: confirmingSigned ? <CircularProgress /> : (
                <Button
                    sx={{color: "black", fontSize:"10px"}}
                    variant="contained"
                    startIcon={ <SendIcon /> }
                    onClick={handleMenuOpen}
                >
                    Signature
                </Button>
            ),
            cancel: (
                <Button
                    sx={{background: "white", color: "black", fontSize:"10px"}}
                    variant="contained"
                    startIcon={<CloseIcon />}
                    onClick={handleCancel}
                    >
                        Cancel
                </Button>
            ),
            pendingSignature: (
                <Button
                    sx={{color: "black", fontSize:"10px"}}
                    variant="contained"
                    startIcon={xodoHash ? <ScheduleRoundedIcon /> : <FileUploadIcon />}
                    onClick={handleOpenFilesUploader}
                    disabled={xodoHash}
                >
                    {xodoHash ? "Pending Signature" : "Upload Signed Topsheet"}
                </Button>
            ),
            signed: (
                <Button
                    color="primary"
                    sx={{color: "black", fontSize:"10px"}}
                    variant="contained"
                    startIcon={<DescriptionOutlinedIcon />}
                    target="_blank"
                    href={file?.url}
                    disabled={!file}
                >
                    Signed Document
                </Button>
            ),
            sendForApproval: (
                <Button
                    sx={{color: "black", fontSize:"10px"}}
                    variant="contained"
                    startIcon={<SendIcon />}
                    onClick={handleAction(constants.BID_ACTIVITY_SUBMITTED_EP)}
                >
                    Send for Approval
                </Button>
            ),
            pendingApproval: (
                <Button
                    sx={{color: "black", fontSize:"10px"}}
                    variant="contained"
                    startIcon={<UpdateIcon />}
                    disabled
                >
                    Approval
                </Button>
            ),
            approve: (
                <Button
                    sx={{color: "black", fontSize:"10px"}}
                    variant="contained"
                    startIcon={<DoneIcon />}
                    onClick={handleAction(constants.BID_ACTIVITY_APPROVED)}
                >
                    Approve
                </Button>
            ),
            reject: (
                <Button
                    sx={{color: "black", fontSize:"10px"}}
                    color="error"
                    variant="contained"
                    startIcon={<CloseIcon />}
                    onClick={handleAction(constants.BID_ACTIVITY_REJECTED)}
                >
                    Reject
                </Button>
            )
        }

        if (!canViewConfidentialData) {
            return [buttons.topsheet]
        }

        if (status == constants.BID_STATUS_DRAFT) {
            if (hasBidApprovalFlow) {
                return [buttons.topsheet, buttons.sendForApproval]
            } else {
                return [buttons.topsheet, buttons.signature]
            }
        }
        else if (status == constants.BID_STATUS_PENDING_EP) {
            if (canApproveBids) {
                return [buttons.topsheet, buttons.reject, buttons.approve]    
            } else {
                return [buttons.topsheet, buttons.cancel, buttons.pendingApproval]
            }
        } else if (status == constants.BID_STATUS_PENDING_FINANCE) {
            if (canApproveBids && isFinance) {
                return [buttons.topsheet, buttons.reject, buttons.approve]
            } else {
                return [buttons.topsheet, buttons.cancel, buttons.pendingApproval]
            }
        } else if (status == constants.BID_STATUS_APPROVED) {
            return [buttons.topsheet, buttons.cancel, buttons.signature]
        } else if (status == constants.BID_STATUS_PENDING_SIGNATURE){
            return [buttons.topsheet, buttons.cancel, buttons.pendingSignature]
        } else if (status == constants.BID_STATUS_SIGNED) {
            return [buttons.topsheet, buttons.signed]
        }

        return []
    }

    if (!bidName) {
        return null
    }

    return (
        <Grid container direction="column" sx={{margin: "24px 0 36px 0"}}>
             <Grid item container justifyContent="space-between" wrap="nowrap">
                <Grid item>
                    <BidTitle bidId={bidId} />
                </Grid>
                <Grid item container justifyContent="flex-end" alignItems="flex-end" spacing={3}>
                    {updatedAt && (
                        <Grid item>
                            <HistoryIcon />
                            <Tooltip title={format(new Date(updatedAt+"Z"), "y-MM-dd h:mm aaa")}>
                                <IconText>{formatDistanceToNow(new Date(updatedAt+"Z"))} ago</IconText>
                            </Tooltip>
                        </Grid>
                    )}

                    {getActionButtons().map((button, i) => (
                        <Grid item key={i} sx={{textAlign: "center"}}>
                            {button}
                        </Grid>
                    ))}

                    <BaseOrganizationComponents>
                        <BidTemplatePublishingSwitch/>
                    </BaseOrganizationComponents>
                </Grid>
            </Grid>

            <Grid item container spacing={3} sx={{padding: "12px 0 36px 0"}}>
                <Grid item>
                    <WorkIcon />
                    <IconText>{project.client.name}</IconText>
                </Grid>
                <Grid item>
                    <NumbersIcon />
                    <InternalLink href={`/projects/${project.id}`}>
                        <IconText>{project.docket}</IconText>
                    </InternalLink>
                </Grid>
                <Grid item>
                    <WalletIcon />
                    <IconText>{project.currency}</IconText>
                </Grid>
                {multiLegalEntity && (
                    <Grid item>
                        <BusinessIcon />
                        <IconText>{project.legal_entity.name}</IconText>
                    </Grid>
                )}
                <Grid item>
                    <PersonIcon />
                    <IconText>{`${project.owner.first_name} ${project.owner.last_name}`}</IconText>
                </Grid>
            </Grid>

            {canViewConfidentialData ? (
                <Grid item>               
                    <BidInsights currency={project.legal_entity.country.currency} />
                </Grid>
            ) : null}

            <BidActionPopup
                bidId={bidId}
                action={actionType}
                isFinance={isFinance}
                open={actionOpen}
                setOpen={setActionOpen}
            />

            <ConfirmationDialog
                open={rdBidIds.length > 0}
                title={`Delete R&D bills?`}
                callback={submitDeleteBills}
            >
                <p>This project has existing bills from an R&D bid sheet. If those bills are no longer valid, would you like to delete them?</p>
            </ConfirmationDialog>

            <ConfirmationDialog
                open={downloadForSignatureOpen}
                closeDialog={() => setDownloadForSignatureOpen(false)}
                title={`Download topsheet for signature`}
                callback={downloadForSignature}
            >
                <p>You are about to download and lock the final version of your bid sheet.</p>
            </ConfirmationDialog>

            <ConfirmationDialog
                open={cancelOpen}
                closeDialog={() => setCancelOpen(false)}
                title={`Are you sure?`}
                callback={submitCancel}
            >
                <p>This will revert to a draft and require re-approval.</p>
            </ConfirmationDialog>

            <ConfirmationDialog
                open={signedOpen}
                closeDialog={() => setSignedOpen(false)}
                title="Heads up..."
                callback={submitSigned}
            >
                Marking this bid as signed will automatically change the status of<br />
                <BoldInline>{ project.name }</BoldInline> to <BoldInline>In Production</BoldInline>.
            </ConfirmationDialog>

            <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                anchorOrigin={{horizontal: "right", vertical: "top"}}
                transformOrigin={{horizontal: "right", vertical: "bottom"}}
                onClose={handleMenuClose}
            >  
                <Tooltip title={signerCount == 0 ? "topsheet has no signer fields, you can adjust this in the topsheet configurator" : ""} placement="left-end">
                    <div>
                        <MenuItem
                            onClick={handleOpenRequestForm}
                            disabled={signerCount == 0}
                        >
                            Request client signature
                        </MenuItem>
                    </div>
                </Tooltip>
                {hasBidApprovalFlow && 
                <MenuItem
                    onClick={handleOpenFilesUploader}
                >
                    Upload signed topsheet
                </MenuItem>}
                {!hasBidApprovalFlow &&
                <MenuItem
                    onClick={()=>{setDownloadForSignatureOpen(true)}}
                >
                    Download for Signature
                </MenuItem>}
            </Menu>

            <TopSheetConfigurator 
                open={topConfigOpen} 
                close={()=>{setTopConfigOpen(false)}}
                requestFormOpen={requestFormOpen}
                setRequestFormOpen={setRequestFormOpen}
            />

            <Modal open={filesUploaderOpen} onClose={()=>setFilesUploaderOpen(false)}>
                <CustomBox sx={{background: theme.palette.background.paper, borderRadius: 3}}>
                    <FilesUploader files={[]} modelId={bidId} modelType={"bid"} onUploadedCallback={handleFileUploaded}/>
                </CustomBox>
            </Modal>
        </Grid>
    )
}
